import {Flex, Heading, Table, TableProps, Tbody, Td, Th, Thead, Tr,} from '@chakra-ui/react';
import {TableControls} from 'components/TableControls/TableControls';
import {useCallback, useEffect, useRef} from 'react';
import {Column, PluginHook, Row, useFlexLayout, useGlobalFilter, useResizeColumns, useSortBy, useTable,} from 'react-table';

interface YCTableProps extends TableProps {
  columns: Column<any>[];
  data: any[];
  useFlex?: boolean;
  dataSearchString?: (data: any) => string;
  onRowClick?: (row: any) => void;
  onBottomReached?: (
    containerRefElement?: HTMLTableSectionElement | null
  ) => void;
  title?: string;
  search?: string;
  setSearch?: (search: string) => void;
  addAction?: () => void;
  downloadAction?: () => void;
  isLoading?: boolean;
}

export const YCTable: React.FC<YCTableProps> = ({
  columns,
  data,
  useFlex,
  search,
  dataSearchString,
  onRowClick,
  onBottomReached,
  title,
  setSearch,
  addAction,
  downloadAction,
  isLoading,
}) => {
  const tableRef = useRef<HTMLTableSectionElement>(null);
  const filter = useCallback(
    (rows: Row[], columnIds: any[], filterValue: string) => {
      if (!filterValue || filterValue === '') return rows;
      const searchString = filterValue.toLowerCase();
      return rows.filter((item: Row) => {
        const dataString = dataSearchString
          ? dataSearchString(item)
          : JSON.stringify(item.values);
        return dataString.toLowerCase().includes(searchString.toLowerCase());
      });
    },
    []
  );

  const hooks: PluginHook<any>[] = [
    useGlobalFilter,
    useSortBy,
    useResizeColumns,
  ];
  if (useFlex) {
    hooks.push(useFlexLayout);
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data, globalFilter: filter }, ...hooks);

  useEffect(() => {
    if (search != null) {
      setGlobalFilter(search);
    }
  }, [search, setGlobalFilter, data]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener('scroll', () => {
        if (tableRef.current) {
          console.log(tableRef.current.scrollTop);
        }
        if (
          tableRef.current &&
          tableRef.current.scrollTop + tableRef.current.clientHeight >=
            tableRef.current.scrollHeight
        ) {
          onBottomReached && onBottomReached(tableRef.current);
        }
      });
    }
  }, [tableRef.current?.scrollHeight]);

  return (
    <Flex flexDir="column">
      {title && <Heading>{title}</Heading>}
      {(search || setSearch || addAction) && (
        <TableControls
          search={search}
          setSearch={setSearch}
          addAction={addAction}
          downloadAction={downloadAction}
        />
      )}
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  paddingInline={0}
                  // display="flex"
                  // justifyContent="center"
                >
                  {column.render('Header')}
                  {/* <chakra.span pl="4">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <TriangleDownIcon aria-label="sorted descending" />
                    ) : (
                      <TriangleUpIcon aria-label="sorted ascending" />
                    )
                  ) : null}
                </chakra.span> */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? 'isResizing' : ''
                    }`}
                  />
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} ref={tableRef}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                onClick={(e) => (onRowClick ? onRowClick(row) : undefined)}
              >
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()} paddingInline={0}>
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Flex>
  );
};
