import { customTheme } from '../../theme';

export const darkTimelineTheme = (hover: boolean = false) => ({
  primary: {
    600: '#1a202c',
    900: '#171923',
  },
  grey: { 300: '#d1d1d1' },
  white: '#fff',
  green: customTheme.colors.green,
  red: {
    300: '#D9534F',
  },
  loader: {
    teal: '#5DDADB',
    purple: '#3437A2',
    pink: '#F78EB6',
    bg: '#171923db',
  },
  scrollbar: {
    border: '#ffffff',
    thumb: {
      bg: '#e1e1e1',
    },
  },
  gradient: {
    blue: {
      300: '#002eb3',
      600: '#002360',
      900: '#051937',
    },
  },
  text: {
    grey: {
      300: '#becee9',
      500: '#f5f5f5',
    },
  },
  timeline: {
    divider: {
      bg: '#718096',
    },
  },
});

export const lightTimelineTheme = (hover: boolean = false) => ({
  primary: {
    600: customTheme.colors.blackAlpha[100],
    900: '#ffffff',
  },
  grey: customTheme.colors.gray,
  white: customTheme.colors.white,
  green: customTheme.colors.green,
  red: {
    300: '#D9534F',
  },
  loader: {
    teal: '#5DDADB',
    purple: '#3437A2',
    pink: '#F78EB6',
    bg: '#171923db',
  },
  scrollbar: {
    border: '#ffffff',
    thumb: {
      bg: '#e1e1e1',
    },
  },
  gradient: {
    blue: {
      300: customTheme.colors.blue[500],
      600: customTheme.colors.blue[200],
      900: customTheme.colors.blue[300],
    },
  },
  text: {
    grey: customTheme.colors.gray,
  },
  timeline: {
    divider: {
      bg: '#718096',
    },
  },
});

export const timelineProgramLightTheme = (hover: boolean = false) => ({
  ...lightTimelineTheme(hover),
  primary: {
    600: customTheme.colors.gray[50],
    900: '#ffffff',
  },
  gradient: {
    blue: {
      300: customTheme.colors.orange[200],
      600: customTheme.colors.orange[300],
      900: customTheme.colors.orange[400],
    },
  },
});

export const timelineProgramLightPlayedTheme = (hover: boolean = false) => ({
  ...timelineProgramLightTheme(hover),
  primary: {
    600: customTheme.colors.gray[50],
    900: '#ffffff',
  },
  gradient: {
    blue: {
      300: customTheme.colors.orange[300],
      600: customTheme.colors.orange[400],
      900: customTheme.colors.orange[500],
    },
  },
});

export const timelineProgramDarkTheme = (hover: boolean = false) => ({
  ...lightTimelineTheme(hover),
  primary: {
    600: customTheme.colors.whiteAlpha[300],
    900: '#ffffff',
  },
  gradient: {
    blue: {
      300: customTheme.colors.blue[400],
      600: customTheme.colors.blue[500],
      900: customTheme.colors.blue[600],
    },
  },
});

export const timelineProgramDarkPlayedTheme = (hover: boolean = false) => ({
  ...timelineProgramDarkTheme(hover),
  primary: {
    600: customTheme.colors.whiteAlpha[100],
    900: '#ffffff',
  },
  gradient: {
    blue: {
      300: customTheme.colors.blue[100],
      600: customTheme.colors.blue[200],
      900: customTheme.colors.blue[300],
    },
  },
});
