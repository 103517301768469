import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

interface FinishGameButtonProps extends ButtonProps {}

export const FinishGameButton: React.FC<FinishGameButtonProps> = ({
  onClick,
  ...rest
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Button {...rest} onClick={onOpen}>
        Finish Game
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Finish Game</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to finish the game? This game will be locked
            and unable to be edited.
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme={rest.colorScheme}
              onClick={(e) => {
                onClose();
                if (onClick) {
                  onClick(e);
                }
              }}
            >
              Finish Game
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
