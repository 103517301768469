import {AddIcon, MinusIcon} from '@chakra-ui/icons';
import {IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,} from '@chakra-ui/react';
import React, {useMemo} from 'react';
import {Row} from 'react-table';
import {useUpdatePlayerStatsMutation} from '../../../app/api';
import {BasketballLoaderOverlay} from '../../../components/BasketballLoader/BasketballLoaderOverlay';
import {YCTable} from '../../../components/YCTable/YCTable';
import {PlayerStats} from '../../../types';

interface Data {
  name: string;
  value: number;
  id: string;
}

interface PlayerScoringModalProps {
  isLoading?: boolean;
  isOpen: boolean;
  onClose: () => void;
  selectedPlayer?: PlayerStats | null;
  refetchGameStats?: () => void;
}

export const PlayerScoringModal: React.FC<PlayerScoringModalProps> = ({
                                                                        isOpen,
                                                                        onClose,
                                                                        selectedPlayer,
                                                                        refetchGameStats,
                                                                        isLoading,
                                                                      }) => {
  const [updatePlayerStats, {isLoading: updatePlayerStatsLoading}] =
    useUpdatePlayerStatsMutation();
  const showLoading = isLoading || updatePlayerStatsLoading;

  const onStatsChange = useMemo(
    () =>
      (
        id: number,
        {
          onePoints,
          twoPoints,
          threePoints,
          rebounds,
          blocks,
          assists,
          steals,
          fouls
        }: PlayerStats
      ) => {
        updatePlayerStats({
          id,
          stats: {
            onePoints,
            twoPoints,
            threePoints,
            rebounds,
            blocks,
            assists,
            steals,
            fouls
          },
        }).then(() => {
          if (refetchGameStats) {
            refetchGameStats();
          }
        });
      },
    [updatePlayerStats, refetchGameStats]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
      },
      {
        Header: 'Remove',
        accessor: 'name',
        id: 'remove',
        Cell: ({row}: { row: Row<Data> }) => (
          <IconButton
            aria-label="Remove"
            disabled={(selectedPlayer as any)[row.original.id] === 0}
            icon={<MinusIcon/>}
            onClick={() => {
              onStatsChange(selectedPlayer!.id, {
                ...selectedPlayer!,
                [row.original.id]: (selectedPlayer as any)[row.original.id] - 1,
              });
            }}
          />
        ),
      },
      {
        Header: 'Value',
        accessor: 'value',
      },
      {
        Header: 'Add',
        accessor: 'name',
        id: 'add',
        Cell: ({row}: { row: Row<PlayerStats> }) => (
          <IconButton
            aria-label="Add"
            icon={<AddIcon/>}
            onClick={() => {
              onStatsChange(selectedPlayer!.id, {
                ...selectedPlayer!,
                [row.original.id]: (selectedPlayer as any)[row.original.id] + 1,
              });
            }}
          />
        ),
      },
    ],
    [selectedPlayer, onStatsChange]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>{selectedPlayer?.name}</ModalHeader>
        <ModalCloseButton disabled={showLoading}/>
        <BasketballLoaderOverlay show={showLoading}/>
        <ModalBody>
          <YCTable
            columns={columns}
            data={[
              {
                name: '2PT',
                value: selectedPlayer?.twoPoints,
                id: 'twoPoints',
              },
              {
                name: '3PT',
                value: selectedPlayer?.threePoints,
                id: 'threePoints',
              },
              {
                name: 'FT',
                value: selectedPlayer?.onePoints,
                id: 'onePoints',
              },
              {
                name: 'Assists',
                value: selectedPlayer?.assists,
                id: 'assists',
              },
              {
                name: 'Rebounds',
                value: selectedPlayer?.rebounds,
                id: 'rebounds',
              },
              {
                name: 'Steals',
                value: selectedPlayer?.steals,
                id: 'steals',
              },
              {
                name: 'Blocks',
                value: selectedPlayer?.blocks,
                id: 'blocks',
              },
              {
                name: 'Fouls',
                value: selectedPlayer?.fouls,
                id: 'fouls',
              },
            ]}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
