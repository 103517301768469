import {Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, useToast} from '@chakra-ui/react';
import Page from '../../components/Page/Page';
import Card from 'components/Card/Card';
import CheckInTable from './CheckInTable';
import {useEventContext} from '../../contexts/EventContext';
import {
    useGetAttendeesForEventQuery,
    useGetMembersForEventQuery,
    useGetPlayersForEventQuery,
    useUpdateAttendeeByIdMutation,
    useUpdateEventRoleMutation,
    useUpdatePlayerByIdMutation
} from '../../app/api';
import {skipToken} from '@reduxjs/toolkit/query';
import {Attendee, EventRoleType, Player, SportEventMember} from '../../types/dto';
import PermissionTable from './PermissionTable';

const EventManagement = ({}) => {
    const {currentEvent, canPerformEventAction} = useEventContext();
    const {data: playerData, refetch: refetchPlayerData} = useGetPlayersForEventQuery(currentEvent ? currentEvent.id : skipToken);
    const {data: attendeeData, refetch: refetchAttendeeData} = useGetAttendeesForEventQuery(currentEvent ? currentEvent.id : skipToken);
    const {
        data: sportEventMemberData,
        refetch: refetchSportEventMemberData
    } = useGetMembersForEventQuery(currentEvent ? currentEvent.id : skipToken);
    const [updateAttendee] = useUpdateAttendeeByIdMutation();
    const [updatePlayer] = useUpdatePlayerByIdMutation();
    const [updateSportEventMember] = useUpdateEventRoleMutation();
    const toast = useToast();

    const handleAttendeeUpdate = (updatedAttendee: Partial<Attendee>) => {
        updateAttendee({attendeeId: updatedAttendee.id!, attendee: updatedAttendee})
            .then(() => {
                toast({
                    title: 'Success',
                    description: 'Update Successful',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                refetchAttendeeData();
            })
            .catch((e) => console.error(e));
    }


    const handlePlayerUpdate = (updatedPlayer: Partial<Attendee>) => {
        updatePlayer({playerId: updatedPlayer.id!, player: updatedPlayer as Player})
            .then(() => {
                toast({
                    title: 'Success',
                    description: 'Update Successful',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                refetchPlayerData();
            })
            .catch((e) => console.error(e));
    }

    const handleSportEventMemberUpdate = (updatedSportEventMember: Partial<SportEventMember>) => {
        updateSportEventMember({eventId: currentEvent!.id, sportEventMember: updatedSportEventMember})
            .then(() => {
                toast({
                    title: 'Success',
                    description: 'Update Successful',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                refetchSportEventMemberData();
            })
            .catch((e) => console.error(e));
    }

    return (
        <>
            <Page flexDir="row" display="flex">
                <Box padding={5} flexGrow={1} overflow="auto">
                    <Card>
                        <Heading>Event Management</Heading>
                    </Card>
                    <Card>
                        <Tabs>
                            <TabList>
                                <Tab>Attendee Check-In</Tab>
                                <Tab>Player Check-In</Tab>
                                {canPerformEventAction(EventRoleType.EVENT_OWNER) && <Tab>Manage Permissions</Tab>}
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <CheckInTable data={attendeeData != null ? attendeeData : []} handleUpdate={handleAttendeeUpdate}/>
                                </TabPanel>
                                <TabPanel>
                                    <CheckInTable data={playerData != null ? playerData : []} handleUpdate={handlePlayerUpdate}/>
                                </TabPanel>
                                <TabPanel>
                                    <PermissionTable data={sportEventMemberData} handleUpdate={handleSportEventMemberUpdate}/>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Card>
                </Box>
            </Page>
        </>
    );
};

export default EventManagement;
