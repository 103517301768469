import { useColorModeValue, Text, HStack, Select } from "@chakra-ui/react";
import { useDroppable } from "@dnd-kit/core";
import { isEqual, startOfDay } from "date-fns";
import { Epg, Layout, ChannelBox, useEpg, Program } from "planby";
import { RefHandler } from "planby/dist/Epg/components";
import React, { useEffect, useMemo, useRef } from "react";
import { GameView } from "types";
import { DroppableTimeSlot } from "./DroppableTimeSlot";
import { GameTimelineItem } from "./GameTimelineItem";
import { useTimelineContext } from "./TimelineContext";
import { lightTimelineTheme, darkTimelineTheme } from "./timelineTheme";

const SLOTS_PER_COURT = 288;

interface GameEPGProps {
  isEditing: boolean;
  hourWidth: number;
  courtHeight: number;
  onContentPointerMove?: (position: {x: number, y: number}) => void;
  onScrollChange?: (scroll: { x: number, y: number }) => void;
}

export const GameEPG: React.FC<GameEPGProps> = ({ isEditing, hourWidth, courtHeight, onContentPointerMove, onScrollChange }) => {
  const savedRefs = useRef<RefHandler | null | undefined>();
  const { selectedDay, schedule, onGameSelect, setSelectedDate, isLoading } = useTimelineContext();

  const startTime = useMemo(() => startOfDay(new Date(selectedDay ? selectedDay.date : new Date())), [selectedDay]);

  const channels = useMemo(
    () => (schedule?.courts || []).map((court) => ({
      uuid: court.id.toString(),
      logo: process.env.PUBLIC_URL + '/logo_full.png',
    })),
    [schedule?.courts]
  );

  const {setNodeRef} = useDroppable({
    id: 'game-schedule',
  });

  const timelineWidth = hourWidth * 24;
  const timelineHeight = (schedule?.courts.length || 2) * courtHeight + courtHeight;

  const epg = useMemo(() => (selectedDay ? Object.values(selectedDay?.games) : []).map((game) => ({
        channelUuid: game.courtId.toString(),
        id: game.id
          ? game.id.toString()
          : `${game.homeTeamId}-${game.awayTeamId}-${game.scheduledAt}`,
        since: game.startTime,
        till: game.endTime,
        image: process.env.PUBLIC_URL + '/logo_full.png',
        description: `${game.homeTeamName} vs. ${game.awayTeamName}`,
        title: `${game.homeTeamName}\n${game.awayTeamName}`,
      })), [selectedDay]);


  const { getEpgProps, getLayoutProps, scrollX, scrollY } = useEpg({
    isBaseTimeFormat: true,
    epg,
    channels,
    startDate: startTime,
    height: timelineHeight,
    theme: useColorModeValue(lightTimelineTheme(false), darkTimelineTheme(false)),
    dayWidth: timelineWidth,
  });

  const onLayoutRefUpdate = (refs: RefHandler | null | undefined): void => {
    savedRefs.current = refs;
    if (refs?.contentRef) {
      setNodeRef(refs?.contentRef.current);
      if (refs.contentRef.current && refs.scrollBoxRef.current) {
        refs.contentRef.current.onpointermove = (ev) => {
          // console.log(ev);
          if (refs.contentRef.current && refs.scrollBoxRef.current && onContentPointerMove) {
            const rect = refs.contentRef.current.getBoundingClientRect();
            const scrollTop = refs.scrollBoxRef.current.scrollTop;
            const scrollLeft = refs.scrollBoxRef.current.scrollLeft;
            onContentPointerMove({ x: ev.clientX - rect.x + scrollLeft, y: ev.clientY - rect.y + scrollTop });
          }
        }
      }
    }
  }

  useEffect(() => {
    if (onScrollChange) {
      onScrollChange({ x: scrollX, y: scrollY });
    }
  }, [scrollX, scrollY, onScrollChange]);

  const changeDay: React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
      setSelectedDate(new Date(ev.target.selectedOptions[0].value));
  }


  return (
    <>
      <HStack>
        {schedule && schedule.days.length > 0 && (
          <Select isDisabled={isLoading} onChange={changeDay}>
            {schedule && schedule.days.map((day) => (
              <option
                value={day.date.toString()}
                key={day.date.toString()}
              >
                {new Date(day.date).toLocaleDateString()}
              </option>
            ))}
          </Select>
        )}
        {/* <Select>
          {schedule.courts.map((court) => (
            <option value={court.id} key={court.id}>
              {court.name}
            </option>
          ))}
        </Select> */}
      </HStack>
      {selectedDay &&
        <Epg {...getEpgProps()}>
          <Layout
            {...getLayoutProps()}
            // TODO fix
            ref={onLayoutRefUpdate}
            renderChannel={({ channel }) => {
              return (
                <ChannelBox
                  height={80}
                  top={channel.position.top}
                  key={channel.uuid}
                >
                  <Text>
                    {
                      schedule?.courts.find(
                        (court) => court.id === parseInt(channel.uuid)
                      )?.name
                    }
                  </Text>
                </ChannelBox>
              );
            }}
            renderProgram={(program) => {
              if (program.program.data.id.startsWith('droppable-')) {
                return <DroppableTimeSlot key={program.program.data.id} program={program} />;
              } else {
                const game = selectedDay!.games[parseInt(program.program.data.id)];
                return (
                  <GameTimelineItem
                    game={game!}
                    editing={isEditing}
                    program={program}
                    key={game!.id}
                    onGameSelect={() => onGameSelect && !isEditing ? onGameSelect(game!) : undefined}
                  />
                );
              }
            }
            }
          />
        </Epg>
      }
    </>
  )
}