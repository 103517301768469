import CreateModal from '../../components/CreateModal';
import * as yup from 'yup';
import {
  Tag,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { League } from '../../types';
import LeagueOptions from '../../components/LeagueSelectOptions/LeagueSelectOptions';
import { useCreateEventMutation } from '../../app/api';

interface CreateSportEventModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  afterSubmit: (id: number) => void;
}

const CreateSportEventModal: React.FC<CreateSportEventModalProps> = ({
  isOpen,
  onClose,
  onOpen,
  afterSubmit,
}) => {
  const [createEvent, { data, error }] = useCreateEventMutation();
  return (
    <CreateModal
      onClose={onClose}
      onOpen={onOpen}
      isOpen={isOpen}
      onSubmit={async (values, formik) => {
        const vals = values as CreateSportEventFormValues;
        const result = await createEvent({
          event: {
            startDate: vals.dates[0],
            endDate: vals.dates[1],
            name: vals.name,
            description: vals.description,
          },
          leagues: vals.leagues.map((league) => league.id),
        }).unwrap();
        formik.setSubmitting(false);
        onClose();
        afterSubmit(result.id);
      }}
      initialValues={{
        name: '',
        description: '',
        dates: [new Date(), new Date()],
        leagues: [],
      }}
      validationSchema={yup.object().shape({
        name: yup.string().required('Name is required'),
        description: yup.string().required('Description is required'),
      })}
      header="Create Event"
      inputs={[
        {
          htmlFor: 'name',
          label: 'Name',
          type: 'text',
        },
        {
          htmlFor: 'description',
          label: 'Description',
          type: 'text',
        },
        {
          htmlFor: 'dates',
          label: 'Dates',
          type: ({ formik }) => (
            <RangeDatepicker
              selectedDates={formik.values.dates}
              onDateChange={(dates) => formik.setFieldValue('dates', dates)}
            />
          ),
        },
        {
          htmlFor: 'leagues',
          label: 'Associated Leagues',
          type: ({ formik }) => (
            <>
              <HStack>
                {formik.values.leagues.map((league: League) => (
                  <Tag key={league.id}>
                    <TagLabel>{league.name}</TagLabel>
                    <TagCloseButton
                      onClick={() => {
                        formik.values.leagues.splice(
                          formik.values.leagues.indexOf(league),
                          1
                        );
                        formik.setFieldValue('leagues', formik.values.leagues);
                      }}
                    />
                  </Tag>
                ))}
              </HStack>
              <Menu isLazy>
                <MenuButton as={Button} color="blue.500">
                  Select Leagues
                </MenuButton>
                <MenuList>
                  <LeagueOptions
                    addLeague={(league) =>
                      formik.setFieldValue('leagues', [
                        ...formik.values.leagues,
                        league,
                      ])
                    }
                  />
                </MenuList>
              </Menu>
            </>
          ),
        },
      ]}
    />
  );
};

interface CreateSportEventFormValues {
  name: string;
  description: string;
  dates: [Date, Date];
  leagues: League[];
}

export default CreateSportEventModal;
