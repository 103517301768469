import { createStandaloneToast } from '@chakra-ui/react';
import { useAppDispatch } from 'app/store';
import { useEffect } from 'react';
import {
  useNotifications,
  NotificationInterface,
  dismissNotification,
} from '../app/slices/notification';

const toast = createStandaloneToast();

export const Notifications = () => {
  const notifications = useNotifications();
  const dispatch = useAppDispatch();

  useEffect(() => {
    notifications.forEach((notification: NotificationInterface) => {
      toast({
        title: notification.type,
        description: notification.message,
        status: notification.type,
        duration: notification.autoHideDuration || 5000,
        isClosable: true,
        onCloseComplete() {
          dispatch(dismissNotification(notification.id));
        },
      });
    });
  }, [notifications]);

  return <></>;
};
