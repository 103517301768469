import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

export interface MediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  mediaType?: string;
  mediaUrl?: string;
  title?: string;
}

export const MediaModal: React.FC<MediaModalProps> = ({
  isOpen,
  onClose,
  mediaType,
  mediaUrl,
  title,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {mediaType?.startsWith('image') ? (
            <img src={mediaUrl} />
          ) : (
            <video controls src={mediaUrl} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
