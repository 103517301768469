import { Center } from "@chakra-ui/react";
import { BounceBallLoader } from "react-bounce-ball-loader"
import styled from 'styled-components'

const Loader = styled(BounceBallLoader)`
`;

export const BasketballLoader: React.FC<{}> = () => {
    return (
        <Center h="100%" w="100%">
            <Loader balls={[
                {
                    background: `url(${process.env.PUBLIC_URL + '/basketball.svg'})`,
                    easeDelay: 0
                },
                {
                    background: `url(${process.env.PUBLIC_URL + '/basketball.svg'})`,
                    easeDelay: .15
                },
                {
                    background: `url(${process.env.PUBLIC_URL + '/basketball.svg'})`,
                    easeDelay: .25
                },
                {
                    background: `url(${process.env.PUBLIC_URL + '/basketball.svg'})`,
                    easeDelay: .35
                }
            ]} />
        </Center>
    );
}