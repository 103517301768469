import {Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast} from '@chakra-ui/react';
import React, {useState} from 'react';
import {FileUploadInput} from '../../components/FileUploadInput';
import {useUpdateEventDataMutation, useUploadEventDataMutation} from '../../app/api';

interface UpdateEventModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  eventId: number;
}

const UpdateEventModal: React.FC<UpdateEventModalProps> = ({
                                                           isOpen,
                                                           onOpen,
                                                           onClose,
                                                           eventId,
                                                         }) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [uploadEventDataMutation] = useUploadEventDataMutation();
  const [updateEventData] = useUpdateEventDataMutation();
  const toast = useToast();


  const handleUpload = () => {
    if (selectedFile) {
      const file_form = new FormData();
      file_form.append('file', selectedFile);
      uploadEventDataMutation({file: file_form, eventId})
        .unwrap()
        .then(({fileName}) => updateEventData({eventId, fileName}))
        .then(() => {
          toast({
            title: 'Success',
            description: 'Data uploaded successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };


  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Update Information</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <FileUploadInput
            label={'Upload Information (.csv file)'}
            accept=".csv"
            handleUpload={handleUpload}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateEventModal;
