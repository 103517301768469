import {Box, Checkbox, Heading, Link, useDisclosure, useToast} from '@chakra-ui/react';
import {useGetRegularSeasonGamesForEventQuery, useVerifyGameMutation} from '../../app/api';
import Page from '../../components/Page/Page';
import {Link as RouterLink} from 'react-router-dom';
import {TableControls} from '../../components/TableControls/TableControls';
import {YCTable} from '../../components/YCTable/YCTable';
import {useMemo} from 'react';
import {useEventContext} from '../../contexts/EventContext';
import {useOrganizationContext} from '../../contexts/OrganizationContext';
import CreateGameModal from './CreateGameModal';
import {skipToken} from '@reduxjs/toolkit/query/react';
import {Cell} from 'react-table';
import {Game} from '../../types';

const RegularSeasonGames = ({}) => {
  const {currentEvent} = useEventContext();
  const {currentOrg} = useOrganizationContext();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {data,refetch} = useGetRegularSeasonGamesForEventQuery(currentEvent?.id ?? skipToken);
  const [verifyGame] = useVerifyGameMutation();
  const toast = useToast();

  const columns = useMemo(
    () => [
      {
        Header: 'Start Time',
        accessor: 'startTime',
        Cell: ({value}: { value: string }) =>
          new Date(value).toLocaleString(),
      },
      {
        Header: 'League',
        accessor: 'leagueId'
      },
      {
        Header: 'Home Team',
        accessor: 'homeTeamName',
      },
      {
        Header: 'Away Team',
        accessor: 'awayTeamName',
      },
      {
        Header: 'Played',
        accessor: 'played',
        Cell: ({value}: Cell<Game>) => (
          <Checkbox
            isChecked={value}
            disabled
          />
        ),
      },
      {
        Header: 'Verified',
        accessor: 'verified',
        Cell: ({value, row}: Cell<Game>) => (
          <Checkbox
            isChecked={value}
            isDisabled={value}
            onChange={() => {
              verifyGame({id: row.original.id})
                .then(() => {
                  toast({
                    title: 'Success',
                    description: 'Game Verified',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });
                  refetch();
                })
                .catch((error) => console.error(error)
                )
            }}
          />
        ),
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({value}: { value: string }) => (
          <Link color="blue.500" as={RouterLink}
                to={(currentEvent != null && currentOrg != null) ?
                  `/organizations/${currentOrg.id}/events/${currentEvent.id}/ScoringV2/${value}` :
                  `/`}>
            View
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Page flexDir="row" display="flex">
        <Box padding={5} flexGrow={1} overflow="auto">
          <Heading>Games</Heading>
          <TableControls addAction={onOpen}/>
          <YCTable columns={columns} data={data || []}/>
        </Box>
      </Page>
      <CreateGameModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} afterSubmit={() => refetch()}
                       eventId={currentEvent?.id || -1}/>
    </>
  );
};

export default RegularSeasonGames;
