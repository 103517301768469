import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
  VStack
} from '@chakra-ui/react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDeleteMenuForEventMutation, useGetMenuByIdQuery, useGetMenusByEventIdQuery, useUpdateMenuForEventMutation} from '../../app/api';
import Page from '../../components/Page/Page';
import {useEventContext} from '../../contexts/EventContext';
import {DateTimeInput} from '../../components/DateTimeInput';
import {useEffect, useState} from 'react';
import {FoodItem, Menu} from '../../types/dto';
import {useOrganizationContext} from '../../contexts/OrganizationContext';
import {skipToken} from '@reduxjs/toolkit/query/react';
import {FaTrash} from 'react-icons/all';

export const MenuViewPage: React.FC = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const {currentEvent} = useEventContext();
  const {currentOrg} = useOrganizationContext();
  const {data, refetch} = useGetMenuByIdQuery({
    eventId: currentEvent?.id || -1,
    menuId: id ? parseInt(id) : -1
  });
  const {refetch: refetchMenusByEventId} = useGetMenusByEventIdQuery(currentEvent?.id ?? skipToken)


  const [deleteMenu, {isLoading: isUpdateLoading}] = useDeleteMenuForEventMutation();
  const [updateMenu, {isLoading: isDeleteLoading}] = useUpdateMenuForEventMutation();

  const [title, setTitle] = useState<string>('');
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [endTime, setEndTime] = useState<Date>(new Date());
  const [foodItems, setFoodItems] = useState<FoodItem[]>([]);
  const [newFoodItem, setNewFoodItem] = useState({
    title: '',
    description: '',
  });


  const handleAddFoodItem = () => {
    if (newFoodItem.title && newFoodItem.description) {
      setFoodItems([...foodItems, {
        menuId: id != null ? parseInt(id) : -1,
        description: newFoodItem.description,
        title: newFoodItem.title
      }]);
      setNewFoodItem({title: '', description: ''});
    }
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const updatedMenu: Partial<Menu> = {
      title: title,
      startTime: startTime,
      endTime: endTime,
      items: foodItems.length > 0 ? foodItems : []
    }
    updateMenu({
      eventId: currentEvent?.id || -1,
      menuId: id ? parseInt(id) : -1,
      updatedMenu: updatedMenu
    })
      .unwrap()
      .then(() => {
        toast({
          description: 'Update Successful',
          status: 'success',
          isClosable: true,
          duration: 5000
        });
        refetch();
      })
      .catch(e => {
        console.log(e)
        toast({
          description: 'Something Went Wrong',
          status: 'error',
          isClosable: true,
          duration: 5000
        });
      });
    return;
  }


  const handleDelete = (e: any) => {
    e.preventDefault();
    deleteMenu({
      eventId: currentEvent?.id || -1,
      menuId: id ? parseInt(id) : -1
    })
      .unwrap()
      .then(() => {
        toast({
          description: 'Delete Successful',
          status: 'success',
          isClosable: true,
          duration: 5000
        });
        if (currentEvent != null && currentOrg != null) {
          navigate(`/organizations/${currentOrg.id}/events/${currentEvent.id}/menu`);
          refetchMenusByEventId();
        } else {
          navigate('/');
        }
      })
      .catch(e => {
        console.log(e);
        toast({
          description: 'Something Went Wrong',
          status: 'error',
          isClosable: true,
          duration: 5000
        });
      });
  }

  useEffect(() => {
    if (data != null) {
      setTitle(data.title);
      setStartTime(data.startTime);
      setEndTime(data.endTime);
      setFoodItems(data.items != null ? data.items : []);
    }
  }, [data]);

  return (
    <Page>
      <Box
        bg={useColorModeValue('white', 'gray.700')}
        borderRadius="lg"
        p={8}
        color={useColorModeValue('gray.700', 'whiteAlpha.900')}
        shadow="base">
        {data ? (
          <form>
            <VStack spacing={5}>
              <FormControl isRequired>
                <FormLabel>Title</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Start Time</FormLabel>
                <InputGroup>
                  <DateTimeInput
                    date={startTime}
                    onDateChange={(newDate) => {
                      if (!isNaN(newDate.getTime())) {
                        setStartTime(newDate);
                      }
                    }}
                  />
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>End Time</FormLabel>
                <InputGroup>
                  <DateTimeInput
                    date={endTime}
                    onDateChange={(newDate) => {
                      if (!isNaN(newDate.getTime())) {
                        setEndTime(newDate);
                      }
                    }}
                  />
                </InputGroup>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Food Items:</FormLabel>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Title</Th>
                        <Th>Description</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {foodItems.map((item, index) => (
                        <Tr key={index}>
                          <Td>{item.title}</Td>
                          <Td>{item.description}</Td>
                          <Td>
                            <IconButton
                              colorScheme="red"
                              aria-label="Delete Food Item"
                              icon={<FaTrash/>}
                              onClick={() => {
                                const updatedFoodItems = [...foodItems];
                                updatedFoodItems.splice(index, 1);
                                setFoodItems(updatedFoodItems);
                              }}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </FormControl>


              <FormControl width='auto'>
                <InputGroup mt={8} >
                  <Input
                    mr={4}
                    htmlSize={4} width='auto'
                    type="text"
                    name="newFoodItemTitle"
                    placeholder="New Food Item Title"
                    value={newFoodItem.title}
                    onChange={(e) =>
                      setNewFoodItem({
                        ...newFoodItem,
                        title: e.target.value,
                      })
                    }
                  />
                  <Input
                    mr={4}
                    htmlSize={4} width='auto'
                    type="text"
                    name="newFoodItemDescription"
                    placeholder="New Food Item Description"
                    value={newFoodItem.description}
                    onChange={(e) =>
                      setNewFoodItem({
                        ...newFoodItem,
                        description: e.target.value,
                      })
                    }
                  />
                  <Button onClick={handleAddFoodItem}>+</Button>
                </InputGroup>
              </FormControl>

              <ButtonGroup variant="outline" spacing="6" mt={8} align="center">
                <Button
                  colorScheme="blue"
                  type="submit"
                  onClick={handleUpdate}
                  isDisabled={isDeleteLoading}
                  isLoading={isUpdateLoading}
                >
                  Update
                </Button>
                <Button
                  type="submit"
                  onClick={handleDelete}
                  isDisabled={isUpdateLoading}
                  isLoading={isDeleteLoading}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </VStack>
          </form>
        ) : null}
      </Box>
    </Page>
  );
};


