import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  HStack,
  VStack,
  Heading,
  Divider,
  Text,
  Box,
  IconButton,
  Stack,
  StackProps,
  Spinner,
  Input,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { Cell, Row } from 'react-table';
import { sum, useDebouncedCallbackWithCount } from '../../app/utils';
import { YCTable } from '../../components/YCTable/YCTable';
import { PlayerStats } from '../../types';

interface AddSubtractProps {
  disableAdd?: boolean;
  disableSubtract?: boolean;
  addAction: () => void;
  subtractAction: () => void;
}

export const AddSubtract: React.FC<AddSubtractProps> = ({
  disableAdd,
  disableSubtract,
  addAction,
  subtractAction,
  children,
}) => {
  return (
    <VStack>
      <IconButton
        isDisabled={disableAdd}
        size="xs"
        icon={<AddIcon />}
        aria-label={'add-points'}
        onClick={addAction}
      />
      {children}
      <IconButton
        isDisabled={disableSubtract}
        size="xs"
        icon={<MinusIcon />}
        aria-label={'subtract-points'}
        onClick={subtractAction}
      />
    </VStack>
  );
};

interface TeamStatsProps {
  id: number;
  name: string;
  drawId: number | null;
  score: number;
  won: boolean;
  isHome: boolean;
  players: PlayerStats[] | undefined;
  editable?: boolean;
  editModeManual?: boolean;
  onStatsChange?: (id: number, stats: PlayerStats) => void;
  updatingPlayerIds?: number[];
  playerNameStyler?: (id: number, name: string) => StackProps;
  onPlayerClick?: (id: number) => void;
}

export const TeamStats: React.FC<TeamStatsProps> = ({
  id,
  drawId,
  name,
  score,
  won,
  isHome,
  players,
  editable = false,
  editModeManual = false,
  onStatsChange = () => {},
  updatingPlayerIds = [],
  playerNameStyler = () => {},
  onPlayerClick = () => {},
}) => {
  const columns = useMemo(() => {
    const changeStats = (
      count: number,
      key: 'points' | 'rebounds' | 'assists' | 'steals' | 'blocks' | 'fouls',
      row: Row<PlayerStats>
    ) => {
      const magnitude = Math.abs(count);
      const sign = count > 0 ? 1 : -1;
      if (key === 'points') {
        if (magnitude === 1) {
          if (row.original.onePoints + sign >= 0) {
            onStatsChange(row.original.id, {
              ...row.original,
              onePoints: row.original.onePoints + sign * 1,
            });
          }
        } else if (magnitude === 2) {
          if (row.original.twoPoints + sign >= 0) {
            onStatsChange(row.original.id, {
              ...row.original,
              twoPoints: row.original.twoPoints + sign * 1,
            });
          }
        } else if (magnitude === 3) {
          if (row.original.threePoints + sign >= 0) {
            onStatsChange(row.original.id, {
              ...row.original,
              threePoints: row.original.threePoints + sign * 1,
            });
          }
        }
      } else {
        if (row.original[key] + sign * magnitude >= 0) {
          onStatsChange(row.original.id, {
            ...row.original,
            [key]: row.original[key] + sign * magnitude,
          });
        }
      }
    };
    return [
      {
        Header: 'Player',
        accessor: 'name',
        Cell: ({ value, row }: Cell<PlayerStats>) => {
          const [first, last] = value.split(' ');
          return (
            <HStack
              {...playerNameStyler(row.original.id, value)}
              onClick={() => onPlayerClick(row.original.id)}
              overflow="hidden"
            >
              <VStack align="left">
                <Text noOfLines={1}>{first}</Text>
                <Text noOfLines={1}>{last}</Text>
              </VStack>
              {updatingPlayerIds.includes(row.original.id) && <Spinner />}
              {/* <DraggableScoringHandle /> */}
            </HStack>
          );
        },
        width: 1,
      },
      {
        Header: 'Pts',
        Cell: ({ row }: Cell<PlayerStats>) => {
          const [pointCount, debounceChange, cancelDebounce] =
            useDebouncedCallbackWithCount<number>(
              (actions) => changeStats(actions.reduce(sum), 'points', row),
              1000
            );
          const numPoints =
            row.original.onePoints +
            row.original.twoPoints * 2 +
            row.original.threePoints * 3;
          if (pointCount.length && Math.abs(pointCount.reduce(sum)) > 3) {
            cancelDebounce();
          }
          return (
            <HStack>
              {!editModeManual && (
                <Text>
                  {pointCount.length
                    ? (pointCount.reduce(sum) > 0 ? '+' : '') +
                      pointCount.reduce(sum)
                    : numPoints}
                </Text>
              )}
              {editable && !editModeManual && (
                <AddSubtract
                  disableAdd={
                    pointCount.length > 0 && pointCount.reduce(sum) > 2
                  }
                  disableSubtract={
                    pointCount.length > 0 && pointCount.reduce(sum) < -2
                  }
                  addAction={() => debounceChange(1)}
                  subtractAction={() => debounceChange(-1)}
                />
              )}
              {editable && editModeManual && (
                <Input
                  value={numPoints}
                  onChange={(e) => changeStats(1, 'points', row)}
                />
              )}
            </HStack>
          );
        },
        width: 1,
      },
      {
        Header: 'Reb',
        accessor: 'rebounds',
        Cell: ({ value, row }: Cell<PlayerStats>) => {
          return (
            <HStack>
              <Text>{value}</Text>
              {editable && (
                <AddSubtract
                  addAction={() => changeStats(1, 'rebounds', row)}
                  subtractAction={() => changeStats(-1, 'rebounds', row)}
                />
              )}
            </HStack>
          );
        },
        width: 1,
      },
      {
        Header: 'Ast',
        accessor: 'assists',
        Cell: ({ row }: Cell<PlayerStats>) => {
          return (
            <HStack>
              <Text>{row.original.assists}</Text>
              {editable && (
                <AddSubtract
                  addAction={() => changeStats(1, 'assists', row)}
                  subtractAction={() => changeStats(-1, 'assists', row)}
                />
              )}{' '}
            </HStack>
          );
        },
        width: 1,
      },
      {
        Header: 'Stl',
        accessor: 'steals',
        Cell: ({ row }: Cell<PlayerStats>) => {
          return (
            <HStack>
              <Text>{row.original.steals}</Text>
              {editable && (
                <AddSubtract
                  addAction={() => changeStats(1, 'steals', row)}
                  subtractAction={() => changeStats(-1, 'steals', row)}
                />
              )}{' '}
            </HStack>
          );
        },
        width: 1,
      },
      {
        Header: 'Blk',
        accessor: 'blocks',
        Cell: ({ row }: Cell<PlayerStats>) => {
          return (
            <HStack>
              <Text>{row.original.blocks}</Text>
              {editable && (
                <AddSubtract
                  addAction={() => changeStats(1, 'blocks', row)}
                  subtractAction={() => changeStats(-1, 'blocks', row)}
                />
              )}
            </HStack>
          );
        },
        width: 1,
      },
      {
        Header: 'FOUL',
        accessor: 'fouls',
        Cell: ({ row }: Cell<PlayerStats>) => {
          return (
            <HStack>
              <Text>{row.original.fouls}</Text>
              {editable && (
                <AddSubtract
                  addAction={() => changeStats(1, 'fouls', row)}
                  subtractAction={() => changeStats(-1, 'fouls', row)}
                />
              )}
            </HStack>
          );
        },
        width: 1,
      },
    ];
  }, [editable, onStatsChange, onPlayerClick, playerNameStyler]);

  return (
    <VStack overflow="auto" w="full">
      <Stack direction={editable ? 'row' : 'column'} align="center">
        <Heading as="h3" size={editable ? 'xs' : 'md'}>
          {isHome ? 'Home:' : 'Away:'} #{drawId || id}
        </Heading>
        <Heading as="h2" size={editable ? 'md' : 'lg'}>
          {name}
        </Heading>
        <Heading
          as="h3"
          size={editable ? 'xs' : 'md'}
          color={won ? 'green.500' : 'red.500'}
        >
          {score}
        </Heading>
      </Stack>
      <Divider />
      {players && (
        <Box width="100%" overflow="auto">
          <YCTable useFlex columns={columns} data={players} size="sm" />
        </Box>
      )}
    </VStack>
  );
};
