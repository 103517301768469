import {Box, Heading, HStack, Stack, StatGroup, Tag, VStack,} from '@chakra-ui/react';
import Page from '../../components/Page/Page';
import Card from '../../components/Card/Card';
import {useGetLeagueByIdQuery, useGetPlayerByIdQuery, useGetTeamByIdQuery,} from '../../app/api';
import {useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/query/react';
import SkeletonStats from '../../components/SkeletonStats/SkeletonStats';

const PlayerViewPage = ({}) => {
  const { id } = useParams();
  const { data, error, isLoading } = useGetPlayerByIdQuery(
    id ? parseInt(id) : skipToken
  );
  const { data: team, error: teamError } = useGetTeamByIdQuery(
    data?.teamId ?? skipToken
  );
  const { data: league, error: leagueError } = useGetLeagueByIdQuery(
    team?.leagueId ?? skipToken
  );

  return (
    <Page display="flex" flexDir="row">
      <Box p={5} h="100vh" width="100vw">
        <HStack>
          <Heading>{data?.name}</Heading>
          <Tag size="lg">{team?.name}</Tag>
          <Tag size="lg">{league?.name}</Tag>
        </HStack>
        <VStack h="100%" spacing={2}>
          <Card alignItems="stretch">
            <StatGroup>
              <SkeletonStats
                isLoading={isLoading}
                stat={
                  data ? new Date(data.dob).toLocaleDateString() : undefined
                }
                label={'Date of Birth'}
              />
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.phone}
                label={'Phone Number'}
              />
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.email}
                label={'Email'}
              />
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.hotelName}
                label={'Hotel Name'}
              />
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.roomNumber}
                label={'Room Number'}
              />
            </StatGroup>
          </Card>
          <Card alignItems="stretch">
            <StatGroup flexWrap="wrap">
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.totalPoints}
                label={'Total Points'}
              />
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.totalRebounds}
                label={'Total Rebounds'}
              />
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.totalAssists}
                label={'Total Assists'}
              />
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.totalSteals}
                label={'Total Steals'}
              />
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.totalBlocks}
                label={'Total Blocks'}
              />
            </StatGroup>
          </Card>
          <Stack direction={{ base: 'column', md: 'row' }} w="100%">
            <Card w={{ base: '100%', md: '50%' }}>
              <Heading>PPG Past 5 Games</Heading>
            </Card>
            <Card w={{ base: '100%', md: '50%' }}>
              <Heading>Scoring Breakdown</Heading>
            </Card>
          </Stack>
        </VStack>
      </Box>
    </Page>
  );
};

export default PlayerViewPage;
