import {
  Button,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useSimulateRegularSeasonMutation } from 'app/api';
import { useEventContext } from 'contexts/EventContext';
import { useState } from 'react';

export interface SimulateGamesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SimulateGamesModal: React.FC<SimulateGamesModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { currentEvent } = useEventContext();
  const [confirmText, setConfirmText] = useState<string>('');
  const [simulateRegularSeason, { data, isLoading }] =
    useSimulateRegularSeasonMutation();

  // useEffect(() => {
  //   if (data) {
  //     window.location.reload(); // TODO: This is a hack, should be onClose and update cache
  //   }
  // }, [data]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Simulate Games</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <Text>
              Are you sure you want to simulate all regular season games?
            </Text>
            <Text textColor="red">This action cannot be undone.</Text>
            <InputGroup>
              <Text>Enter "simulate" to confirm</Text>
              <Input
                type="text"
                value={confirmText}
                onChange={(e) => setConfirmText(e.target.value)}
              />
            </InputGroup>
            <Button
              isDisabled={confirmText !== 'simulate'}
              colorScheme="green"
              isLoading={isLoading}
              onClick={() => {
                if (currentEvent) {
                  simulateRegularSeason({
                    eventId: currentEvent.id,
                  });
                }
              }}
            >
              Simulate
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
