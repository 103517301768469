import CreateModal from '../../components/CreateModal';
import * as yup from 'yup';

interface CreateLeagueModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const CreatePlayerModal: React.FC<CreateLeagueModalProps> = ({
  isOpen,
  onClose,
  onOpen,
}) => {
  return (
    <CreateModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      header={'Create Player'}
      initialValues={{
        name: '',
        description: '',
      }}
      inputs={[
        {
          htmlFor: 'name',
          label: 'Name',
          type: 'text',
        },
        {
          htmlFor: 'description',
          label: 'Description',
          type: 'text',
        },
      ]}
      validationSchema={yup.object().shape({
        name: yup.string().required('Name is required'),
        description: yup.string().required('Description is required'),
      })}
      onSubmit={(values) => {
        console.log(values);
      }}
    />
  );
};

export default CreatePlayerModal;
