import { Box, Heading } from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { BasketballLoaderOverlay } from 'components/BasketballLoader/BasketballLoaderOverlay';
import { useEffect, useState } from 'react';
import { useGetScheduleForEventQuery } from '../../../app/api';
import { GameTimeline } from '../../../components/GameTimeline/GameTimeline';
import Page from '../../../components/Page/Page';
import { useEventContext } from '../../../contexts/EventContext';
import { GameView } from '../../../types';
import GameViewModal from '../GameViewModal';

const GameScheduleView = ({}) => {
  const { currentEvent } = useEventContext();
  const { data, error, isLoading } = useGetScheduleForEventQuery(
    currentEvent?.id ?? skipToken
  );
  const [selectedGame, setSelectedGame] = useState<GameView | undefined>(
    undefined
  );

  return (
    <>
      <Page flexDir="row" display="flex">
        <Box p={5} maxW="98vw">
          <Heading>Schedule</Heading>
          {data ? (
            <GameTimeline
              schedule={data}
              onGameSelect={setSelectedGame}
              editingSchedule={data}
            />
          ) : (
            <BasketballLoaderOverlay show={true} />
          )}
        </Box>
      </Page>
      {selectedGame && (
        <GameViewModal
          isOpen={!!selectedGame}
          game={selectedGame}
          onClose={() => setSelectedGame(undefined)}
        />
      )}
    </>
  );
};

export default GameScheduleView;
