import React, {useState} from 'react';
import * as yup from 'yup';
import {useGetHotelsForEventQuery, useQueueNotificationMessageMutation} from '../../app/api';
import {Select} from '@chakra-ui/react';
import {FormikHelpers, FormikProps} from 'formik';
import CreateModal from '../../components/CreateModal';

interface CreatePushNotificationMessageModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  afterSubmit: () => void;
  eventId: number;
}

const CreatePushNotificationMessageModal: React.FC<CreatePushNotificationMessageModalProps> = ({
                                                                                                 isOpen,
                                                                                                 onClose,
                                                                                                 onOpen,
                                                                                                 afterSubmit,
                                                                                                 eventId,
                                                                                               }) => {
  const [queueNotificationMessage] = useQueueNotificationMessageMutation();
  const {data: hotelOptions} = useGetHotelsForEventQuery(eventId);
  const [subscriptionType, setSubscriptionType] = useState<'HOTEL' | 'GENERAL' | 'PLAYER' | 'ATTENDEE'>('GENERAL');

  return (
    <CreateModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      header={'Create Push Notification Message'}
      initialValues={{
        title: '',
        body: '',
        startTime: new Date(),
        endTime: new Date(),
        subscriptionType: 'GENERAL',
        resourceId: undefined,
      }}
      inputs={[
        {
          htmlFor: 'title',
          label: 'Title',
          type: 'text',
        },
        {
          htmlFor: 'body',
          label: 'Body',
          type: 'textarea',
        },
        {
          htmlFor: 'subscriptionType',
          label: 'Subscription Type',
          type: ({formik}: { formik: FormikProps<any> }) => (
            <Select
              value={formik.values.subscriptionType}
              onChange={(e) => {
                const newType = e.target.value as 'HOTEL' | 'GENERAL' | 'PLAYER' | 'ATTENDEE';
                setSubscriptionType(newType);
                formik.setFieldValue('subscriptionType', newType);
                if (newType !== 'HOTEL') {
                  formik.setFieldValue('resourceId', undefined);
                }
              }}
            >
              <option value="HOTEL">HOTEL</option>
              <option value="GENERAL">GENERAL</option>
              <option value="PLAYER">PLAYER</option>
              <option value="ATTENDEE">ATTENDEE</option>
            </Select>
          ),
        },
        subscriptionType === 'HOTEL'
          ? {
            htmlFor: 'resourceId',
            label: 'Hotel',
            type: ({formik}: { formik: FormikProps<any> }) => (
              <Select
                value={formik.values.resourceId}
                onChange={(e) => formik.setFieldValue('resourceId', parseInt(e.target.value))}
              >
                <option value="">Select a hotel</option>
                {hotelOptions?.map((hotel) => (
                  <option key={hotel.id} value={hotel.id}>
                    {hotel.name}
                  </option>
                ))}
              </Select>
            ),
          }
          : {
            htmlFor: 'resourceId',
            label: 'Resource',
            type: ({formik}: { formik: FormikProps<any> }) => (
              <Select isReadOnly value={formik.values.resourceId} >
                <option value={0}>No value needed</option>
              </Select>
            ),
          },
      ]}
      validationSchema={yup.object().shape({
        title: yup.string().required('Title is required'),
        body: yup.string().required('Body is required'),
        subscriptionType: yup
          .string()
          .oneOf(['HOTEL', 'GENERAL', 'PLAYER', 'ATTENDEE'])
          .required('Subscription Type is required'),
        resourceId: yup.number().when('subscriptionType', {
          is: 'HOTEL',
          then: yup.number().required('Hotel Resource ID is required'),
        }),
      })}
      onSubmit={async (values: any, formik: FormikHelpers<object>) => {
        const {title, body, subscriptionType, resourceId} = values;
        await queueNotificationMessage({
          queueNotification: {
            title,
            body,
            subscriptionType,
            eventId,
            resourceId: subscriptionType === 'HOTEL' ? resourceId : undefined,
          },
        }).unwrap();
        formik.setSubmitting(false);
        onClose();
        afterSubmit();
      }}
    />
  );
};

export default CreatePushNotificationMessageModal;
