import {PlayoffBracketProps, SingleEliminationPlayoffBracket,} from './SingleEliminationPlayoffBracket';
import {Game, Round} from '../../types/dto';
import {Box, Flex, Heading} from '@chakra-ui/react';

export const DoubleEliminationPlayoffBracket: React.FC<PlayoffBracketProps> = ({
                                                                                 schedule,
                                                                                 teams,
                                                                               }) => {
  const getWinnersBracket = (allRounds: Round[]) => {
    const winnersBracket: Round[] = [];
    let roundId = 1;
    const winnersBracketGameIds: Set<number> = getWinnersBracketGameIds(allRounds);

    allRounds.forEach((round) => {
      const validGames = round.games.filter(
        (game) => winnersBracketGameIds.has(game.id)
      );
      if (validGames.length > 0) {
        winnersBracket.push({
          id: roundId,
          title: `Round ${roundId}`,
          games: validGames,
        });
        roundId += 1;
      }
    });
    return winnersBracket;
  };

  const getLosersBracket = (allRounds: Round[]) => {
    const losersBracket: Round[] = [];
    let roundId = 1;
    const winnersBracketGameIds: Set<number> = getWinnersBracketGameIds(allRounds);

    allRounds.forEach((round) => {
      const validGames = round.games.filter(
        (game) => !winnersBracketGameIds.has(game.id)
      );
      if (validGames.length > 0) {
        losersBracket.push({
          id: roundId,
          title: `Round ${roundId}`,
          games: validGames,
        });
        roundId += 1;
      }
    });
    return losersBracket;
  };

  const getWinnersBracketGameIds = (allRounds: Round[]): Set<number> => {
    const winnersGameIds: Set<number> = new Set();

    const gameMap: Map<number, Partial<Game>> = new Map();
    allRounds.forEach(round => {
      round.games.forEach(game => {
        if (game.id !== null) {
          gameMap.set(game.id, game);
        }
      });
    });

    const round1 = allRounds.find(round => round.id === 1);
    if (round1) {
      round1.games.forEach(game => {
        if (game.id !== null) {
          winnersGameIds.add(game.id);
        }
      });
    }

    allRounds.forEach(round => {
      round.games.forEach(game => {
        if (game.nextGameIdForLoser !== null) {
          winnersGameIds.delete(game.nextGameIdForLoser!);
        }
      });
    });

    let queue: number[] = Array.from(winnersGameIds);

    while (queue.length > 0) {
      const newQueue: number[] = [];

      queue.forEach(currentGameId => {
        const currentGame = gameMap.get(currentGameId);
        if (currentGame && currentGame.nextGameId !== null) {
          if (!winnersGameIds.has(currentGame.nextGameId!)) {
            winnersGameIds.add(currentGame.nextGameId!);
            newQueue.push(currentGame.nextGameId!);
          }
        }
      });
      queue = newQueue;
    }

    return winnersGameIds;
  };


  const winnersBracket: Round[] = getWinnersBracket(schedule);
  const losersBracket: Round[] = getLosersBracket(schedule);
  return (
    <Flex direction="column" align="center" w="100%">
      <Box mb={4} w="100%">
        <Heading as="h1" mb={2}>
          Winner's Bracket
        </Heading>
        <SingleEliminationPlayoffBracket
          schedule={winnersBracket}
          teams={teams}
        />
      </Box>

      <Box w="100%">
        <Heading as="h1" mb={2}>
          Loser's Bracket
        </Heading>
        <SingleEliminationPlayoffBracket
          schedule={losersBracket}
          teams={teams}
        />
      </Box>
    </Flex>
  );
};
