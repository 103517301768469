import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface ScoringInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ScoringInfoModal: React.FC<ScoringInfoModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Scoring Information</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading>Points</Heading>
          <Text>
            Click on the plus or minus icons next to the player's points to add
            or remove points from their stats for this game. By default,
            clicking once adds one point (a free throw). By clicking twice in
            rapid succession, a regular field goal (two points) is added, and
            clicking three times adds a three point field goal (three points).
          </Text>
          <br />
          <Text>
            After adding or subtracting stats, they will be saved automatically,
            and will take a second to reflect the new stats.
          </Text>
          <br />
          <Heading>Other Stats</Heading>
          <Text>
            All other stats will be updated as you click the plus or minus sign.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
