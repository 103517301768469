import { Stack, StackProps, useColorModeValue } from '@chakra-ui/react';

const Card: React.FC<StackProps> = ({ children, ...props }) => {
  return (
    <Stack
      bg={useColorModeValue('#fefefe', 'gray.700')}
      direction="column"
      boxShadow="lg"
      w="100%"
      borderRadius="lg"
      p={4}
      {...props}
    >
      {children}
    </Stack>
  );
};

export default Card;
