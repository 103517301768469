import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useUpdateEventMutation } from '../../../app/api';
import { useEventContext } from '../../../contexts/EventContext';
import { SportEventConfig, SportEvent } from '../../../types/dto';

export interface SportEventconfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SportEventConfigurationModal: React.FC<
  SportEventconfigurationModalProps
> = ({ isOpen, onClose }) => {
  const [updateEvent] = useUpdateEventMutation();
  const { currentEvent } = useEventContext();
  const [changes, setChanges] = useState<SportEventConfig | undefined>();

  const saveSettings = async () => {
    if (changes && currentEvent) {
      await updateEvent({
        id: currentEvent.id,
        config: changes,
        event: currentEvent,
      });
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={saveSettings}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Event Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2}>
            {currentEvent?.config.flags && (
              <FormControl>
                {Object.entries(currentEvent.config.flags).map((flag) => (
                  <HStack justify="space-between">
                    <FormLabel htmlFor={flag[0]}>{flag[0]}</FormLabel>
                    <Switch
                      id={flag[0]}
                      isChecked={
                        changes?.flags && flag[0] in changes?.flags
                          ? (changes?.flags as any)[flag[0]]
                          : flag[1]
                      }
                      onChange={(e) =>
                        setChanges({
                          ...changes,
                          flags: {
                            ...currentEvent.config.flags,
                            ...changes?.flags,
                            [flag[0]]: e.target.checked,
                          },
                        })
                      }
                      x
                    />
                  </HStack>
                ))}
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={useColorModeValue('orange', 'blue')}
            onClick={saveSettings}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
