import { Stack } from '@chakra-ui/react';
import { GameStats, PlayerStats } from 'types';
import { TeamScoringPanel } from './TeamScoringPanel';

interface TwoTeamScoringProps {
  editable?: boolean;
  overriding?: boolean;
  isAdmin?: boolean;
  gameStatsData: GameStats;
  setSelectedPlayer: (player: PlayerStats) => void;
  refetchGameStats?: () => void;
}

export const TwoTeamScoring: React.FC<TwoTeamScoringProps> = ({
  editable,
  overriding,
  isAdmin,
  gameStatsData,
  setSelectedPlayer,
  refetchGameStats,
}) => {
  return (
    <Stack direction={{ base: 'column', md: 'row' }}>
      <TeamScoringPanel
        overriding={isAdmin && overriding}
        editable={editable}
        teamName={gameStatsData.game.homeTeamName}
        players={gameStatsData.homeTeam}
        setSelectedPlayer={setSelectedPlayer}
        triggerRefetch={refetchGameStats}
      />
      <TeamScoringPanel
        overriding={isAdmin && overriding}
        editable={editable}
        teamName={gameStatsData.game.awayTeamName}
        players={gameStatsData.awayTeam}
        setSelectedPlayer={setSelectedPlayer}
        triggerRefetch={refetchGameStats}
      />
    </Stack>
  );
};
