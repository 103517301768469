import { useCallback, useEffect, useRef, useState } from 'react';
import { Team } from '../types';

function checkTeamStatus(team: Team) {
  return team.shirtsGiven ? 2 : 1;
}

type ImmediateCallback = () => void;
type LastCallback = (count: number) => void;
const useDebounceAndThrottle = (
  immediateCallback: ImmediateCallback,
  lastCallback: LastCallback,
  delay: number
): [number, () => void] => {
  const [count, setCount] = useState(0); // number of times the callback has been called
  const immediateCallbackRef = useRef<ImmediateCallback | null>(null);
  const lastCallbackRef = useRef<LastCallback | null>(null);

  useEffect(() => {
    immediateCallbackRef.current = immediateCallback;
  }, [immediateCallback]);

  useEffect(() => {
    lastCallbackRef.current = lastCallback;
  }, [lastCallback]);

  useEffect(() => {
    if (count > 0) {
      if (count === 1) immediateCallbackRef.current?.();
      const fire = () => {
        setCount(0);
        lastCallbackRef.current?.(count);
      };
      const id = setTimeout(fire, delay);
      return () => clearTimeout(id);
    }
  }, [count, delay]);

  return [count, () => setCount(count + 1)];
};

/**
 * Debounces a function for a given delay while storing values in an array.
 */
type DebounceFunctionType<T> = (actions: T[]) => void;
const useDebouncedCallbackWithCount = <T>(
  debounceFunction: DebounceFunctionType<T>,
  delay: number
): [T[], (action: T) => void, () => void] => {
  const debounceFunctionRef = useRef<DebounceFunctionType<T>>();
  const [actions, setActions] = useState<T[]>([]);
  const timer = useRef<number | null>(null);

  useEffect(() => {
    debounceFunctionRef.current = debounceFunction;
  }, [debounceFunction]);

  useEffect(() => {
    if (actions.length > 0) {
      const fire = () => {
        debounceFunctionRef.current?.(actions);
        setActions([]);
      };
      timer.current = window.setTimeout(fire, delay);
      return () => clearTimeout(timer.current ?? undefined);
    }
  }, [actions, delay]);

  return [
    actions,
    (action: T) => setActions([...actions, action]),
    () => clearTimeout(timer.current ?? undefined),
  ];
};

const throttleImpl = (cb: Function, delay: number) => {
  let isThrottled = false;
  return (...args: any) => {
    if (isThrottled) return;
    isThrottled = true;
    cb(...args);
    setTimeout(() => {
      isThrottled = false;
    }, delay);
  };
};

function useThrottle(cb: (...args: any[]) => void, delay: number) {
  const cbRef = useRef(cb);
  useEffect(() => {
    cbRef.current = cb;
  });
  return useCallback(
    throttleImpl((...args: any) => cbRef.current(...args), delay),
    [delay]
  );
}

const sum = (a: number, b: number) => +a + +b;

export {
  checkTeamStatus,
  useDebounceAndThrottle,
  useDebouncedCallbackWithCount,
  useThrottle,
  sum,
};
