import { Heading, Box, Link, useDisclosure } from '@chakra-ui/react';
import { useGetAllEventsQuery } from '../../app/api';
import Page from '../../components/Page/Page';
import { Link as RouterLink } from 'react-router-dom';
import { TableControls } from '../../components/TableControls/TableControls';
import { YCTable } from '../../components/YCTable/YCTable';
import CreateSportEventModal from './CreateSportEventModal';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    Cell: ({ value }: { value: string }) =>
      new Date(value).toLocaleDateString(),
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    Cell: ({ value }: { value: string }) =>
      new Date(value).toLocaleDateString(),
  },
  {
    Header: 'Actions',
    accessor: 'id',
    Cell: ({ value }: { value: string }) => (
      <Link color="blue.500" as={RouterLink} to={`/event/${value}`}>
        View
      </Link>
    ),
  },
];

const SportEvent = ({}) => {
  const { data, error, isLoading, refetch } = useGetAllEventsQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Page flexDir="row" display="flex">
        <Box padding={5} flexGrow={1} overflow="auto">
          <Heading>Events</Heading>
          <TableControls addAction={onOpen} />
          <YCTable columns={columns} data={data || []} />
        </Box>
      </Page>
      <CreateSportEventModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        afterSubmit={() => refetch()}
      />
    </>
  );
};

export default SportEvent;
