import {Box, Heading, Link, useDisclosure} from '@chakra-ui/react';
import Page from '../../components/Page/Page';
import {Link as RouterLink} from 'react-router-dom';
import {TableControls} from '../../components/TableControls/TableControls';
import {YCTable} from '../../components/YCTable/YCTable';
import {useEventContext} from '../../contexts/EventContext';
import Card from 'components/Card/Card';
import {useGetMenusByEventIdQuery} from '../../app/api';
import {DateTimeInput} from '../../components/DateTimeInput';
import {skipToken} from '@reduxjs/toolkit/query/react';
import CreateMenuModal from './CreateMenuModal';

const columns = [
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Start Time',
    accessor: 'startTime',
    Cell: ({value}: { value: Date }) => (<DateTimeInput isDisabled={true} date={value}/>)
  },
  {
    Header: 'End Time',
    accessor: 'endTime',
    Cell: ({value}: { value: Date }) => (<DateTimeInput isDisabled={true} date={value}/>)
  },
  {
    Header: 'Actions',
    accessor: 'id',
    Cell: ({value}: { value: string }) => (
      <Link color="blue.500" as={RouterLink} to={`${value}`}>
        View
      </Link>
    ),
  },
];

const Menu = ({}) => {
  const {currentEvent} = useEventContext();
  const {data, refetch} = useGetMenusByEventIdQuery(currentEvent?.id ?? skipToken)

  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Page flexDir="row" display="flex">
        <Box padding={5} flexGrow={1} overflow="auto">
          <Card>
            <Heading>Menus</Heading>
            <TableControls addAction={onOpen}/>
          </Card>
          <Card mt={2}>
            <YCTable columns={columns} data={data || []}/>
          </Card>
        </Box>
      </Page>
      <CreateMenuModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} afterSubmit={() => refetch()}
                       eventId={currentEvent?.id || -1}/>
    </>
  );
};

export default Menu;
