import { areIntervalsOverlapping, format } from "date-fns";
import { GameView, Schedule } from "types";

export const TIME_FORMAT = {
  DEFAULT: "yyyy-MM-dd HH:mm:ss",
  DATE: "yyyy-MM-dd",
  HOURS_MIN: "HH:mm",
  BASE_HOURS_TIME: "h:mm a",
};

export const getGameFromScheduleById = (currentDay: Date, schedule: Schedule, id: number) => {
    return schedule.days.find((day) => 
      new Date(day.date).getTime() === currentDay.getTime()
    )?.games.find((game) => game.id === id);
}

export const formatTime = (
    date: string | number | Date,
    formatType: string = TIME_FORMAT.HOURS_MIN
  ) => format(new Date(date), formatType).replace(/\s/g, "");

export const findGameById = (games: GameView[], gameId: number) => games.find(g => g.id === gameId);

export const isOverlapping = (a: GameView, b: GameView) => a.courtId === b.courtId && areIntervalsOverlapping({ start: a.startTime, end: a.endTime }, { start: b.startTime, end: b.endTime});