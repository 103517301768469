import {
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { FaFile } from 'react-icons/fa';

interface FileUploadInputProps {
  isLoading?: boolean;
  handleUpload: () => void;
  selectedFile: File | undefined;
  setSelectedFile: (file: File | undefined) => void;
  label?: string;
  accept?: string;
}

export const FileUploadInput: React.FC<FileUploadInputProps> = ({
  handleUpload,
  selectedFile,
  setSelectedFile,
  label,
  accept,
  isLoading,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={FaFile} />}
        />
        <input
          disabled={isLoading}
          hidden
          type="file"
          name="file"
          accept={accept}
          ref={inputRef}
          onChange={(e) => e.target.files && setSelectedFile(e.target.files[0])}
        ></input>
        <Input
          placeholder={'Select a file'}
          onClick={() => inputRef.current?.click()}
          value={selectedFile?.name || ''}
          readOnly={true}
        />
        <Button
          onClick={handleUpload}
          colorScheme={useColorModeValue('orange', 'blue')}
          isLoading={isLoading}
        >
          Submit
        </Button>
      </InputGroup>
    </FormControl>
  );
};
