import {Box, Heading, Link, useDisclosure} from '@chakra-ui/react';
import {useGetPlayersForEventQuery, useGetTeamsForEventQuery,} from '../../app/api';
import Page from '../../components/Page/Page';
import {Link as RouterLink} from 'react-router-dom';
import {TableControls} from '../../components/TableControls/TableControls';
import {YCTable} from '../../components/YCTable/YCTable';
import {useMemo} from 'react';
import CreatePlayerModal from './CreatePlayerModal';
import {useEventContext} from '../../contexts/EventContext';
import {skipToken} from '@reduxjs/toolkit/query/react';
import {Cell} from 'react-table';
import {Player} from '../../types';
import Card from 'components/Card/Card';
import {download, generateCsv, mkConfig} from 'export-to-csv';

const Players = ({}) => {
  const { currentEvent } = useEventContext();
  const { data } = useGetPlayersForEventQuery(currentEvent?.id ?? skipToken);
  const { data: teamData } = useGetTeamsForEventQuery(
    currentEvent?.id ?? skipToken
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Team',
        Cell: ({ row }: Cell<Player>) =>
          teamData?.find((team) => team.id === row.original.teamId)?.name || '',
      },
      {
        Header: 'Total Pts.',
        accessor: 'totalPoints',
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ value }: Cell<Player>) => (
          <Link color="blue.500" as={RouterLink} to={`${value}`}>
            View
          </Link>
        ),
      },
    ],
    []
  );

  const exportPlayerStatsToCSV = () => {
    const csvConfig = mkConfig({useKeysAsHeaders: true, filename: 'player-stats-data'});
    const csvData: any = [];
    if (data != null) {
      data.forEach((player) => {
        csvData.push({
          'BKMS ID': player.bkmsId,
          'NAME': player.name,
          'EMAIL': player.email,
          'TEAM': teamData?.find((team) => team.id === player.teamId)?.name || '',
          'POINTS': player.totalPoints,
          'REBOUNDS': player.totalRebounds,
          'ASSISTS': player.totalAssists,
          'STEALS': player.totalSteals,
          'BLOCKS': player.totalBlocks
        })
      })
    }
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  }

  return (
    <>
      <Page flexDir="row" display="flex">
        <Box padding={5} flexGrow={1} overflow="auto">
          <Card>
            <Heading>Players</Heading>
            <TableControls addAction={onOpen} downloadAction={exportPlayerStatsToCSV}/>
          </Card>
          <Card mt={2}>
            <YCTable columns={columns} data={data || []} />
          </Card>
        </Box>
      </Page>
      <CreatePlayerModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </>
  );
};

export default Players;
