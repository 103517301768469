import { Heading, Box, Link, useDisclosure } from '@chakra-ui/react';
import { useGetAllCourtsQuery, useGetCourtsForEventQuery } from '../../app/api';
import Page from '../../components/Page/Page';
import { Link as RouterLink } from 'react-router-dom';
import { TableControls } from '../../components/TableControls/TableControls';
import { YCTable } from '../../components/YCTable/YCTable';
import CreateCourtModal from './CreateCourtModal';
import { useEventContext } from '../../contexts/EventContext';
import { skipToken } from '@reduxjs/toolkit/query/react';
import Card from 'components/Card/Card';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Event',
    accessor: 'eventId',
  },
  {
    Header: 'Actions',
    accessor: 'id',
    Cell: ({ value }: { value: string }) => (
      <Link color="blue.500" as={RouterLink} to={`${value}`}>
        View
      </Link>
    ),
  },
];

const Courts = ({}) => {
  const { currentEvent } = useEventContext();
  const { data, error, isLoading } = useGetCourtsForEventQuery(
    currentEvent?.id ?? skipToken
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Page flexDir="row" display="flex">
        <Box padding={5} flexGrow={1} overflow="auto">
          <Card>
            <Heading>Courts</Heading>
            <TableControls addAction={onOpen} />
          </Card>
          <Card mt={2}>
            <YCTable columns={columns} data={data || []} />
          </Card>
        </Box>
      </Page>
      <CreateCourtModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </>
  );
};

export default Courts;
