import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function Forbidden() {
  const navigate = useNavigate();
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, red.200, red.400)"
        backgroundClip="text"
      >
        403
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Unauthorized
      </Text>
      <Text color={'gray.500'} mb={6}>
        You don't seem to have access to this page
      </Text>

      <Button
        colorScheme="red"
        bgGradient="linear(to-r, red.200, red.300, red.400)"
        color="white"
        onClick={() => navigate('/')}
        variant="solid"
      >
        Go to Home
      </Button>
    </Box>
  );
}
