import { AddIcon, CheckIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  Heading,
  HStack,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import {
  useDeletePostMutation,
  useGetFeedQuery,
  useMakePostDecisionMutation,
} from 'app/api';
import { YCTable } from 'components/YCTable/YCTable';
import { useEventContext } from 'contexts/EventContext';
import { useEffect, useMemo, useState } from 'react';
import { Cell } from 'react-table';
import { PostView } from 'types/dto';
import Card from '../../components/Card/Card';
import Page from '../../components/Page/Page';
import { UploadPostModal } from './UploadPostModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { MediaModal } from './MediaModal';

interface FeedPageProps {}

const isImage = (post: PostView) =>
  post.mediaType.startsWith('image') ||
  post.mediaId.toLowerCase().endsWith('.heic');

export const FeedPage: React.FC<FeedPageProps> = ({}) => {
  const [cursor, setCursor] = useState<number | undefined>(undefined);
  const [selectedPost, setSelectedPost] = useState<PostView | undefined>(
    undefined
  );
  const [allData, setAllData] = useState<PostView[]>([]);
  const [isNewPost, setIsNewPost] = useState(false);
  const [areMorePosts, setAreMorePosts] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { currentEvent } = useEventContext();
  const { data, error, isLoading, refetch } = useGetFeedQuery(
    { eventId: currentEvent!.id, cursor, approved: false },
    { skip: !currentEvent }
  );
  const [makePostDecision] = useMakePostDecisionMutation();
  const [deletePost] = useDeletePostMutation();

  useEffect(() => {
    if (data?.latestCursor !== allData[allData.length - 1]?.id) {
      setIsNewPost(true);
    } else {
      setIsNewPost(false);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (data.posts.length === 0) {
        setAreMorePosts(false);
      } else {
        const newData: { [key: number]: PostView } = {};
        [...allData, ...data.posts].forEach((post) => {
          newData[post.id] = post;
        });
        setAllData(Object.values(newData).sort((a, b) => b.id - a.id));
      }
    }
  }, [data]);

  const onCloseUploadModal = () => {
    refetch();
    onClose();
  };

  const setApproval = (postId: number, approved: boolean) => {
    const index = allData.findIndex((post) => post.id === postId);
    const newData = [...allData];
    newData[index] = { ...newData[index], approved };
    setAllData(newData);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Media',
        accessor: 'url',
        Cell: ({ row, value }: Cell<PostView>) => {
          return (
            <div onClick={() => setSelectedPost(row.original)}>
              {isImage(row.original) ? (
                <LazyLoadImage src={value} width={100} />
              ) : (
                <video src={value} width={100} preload="metadata" />
              )}
            </div>
          );
        },
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Author',
        accessor: 'userId',
      },
      {
        Header: 'Time',
        accessor: 'timeStamp',
        Cell: ({ value }: { value: string }) => {
          return <>{new Date(value).toLocaleString()}</>;
        },
      },
      {
        Header: 'Status',
        accessor: 'approved',
        Cell: ({ value }: { value: boolean }) => {
          const status =
            value !== null ? (value ? 'Approved' : 'Rejected') : 'Pending';
          const color = value !== null ? (value ? 'green' : 'red') : 'yellow';
          return <Badge colorScheme={color}>{status}</Badge>;
        },
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ value, row }: Cell<PostView>) => {
          return (
            <HStack>
              <IconButton
                colorScheme="green"
                aria-label="Approve"
                icon={<CheckIcon />}
                onClick={() => {
                  makePostDecision({
                    postId: value,
                    eventId: currentEvent!.id,
                    approved: true,
                  }).then(() => setApproval(parseInt(value), true));
                }}
              />
              <IconButton
                colorScheme="red"
                aria-label="Reject"
                icon={<CloseIcon />}
                onClick={() => {
                  makePostDecision({
                    postId: value,
                    eventId: currentEvent!.id,
                    approved: false,
                  }).then(() => setApproval(parseInt(value), false));
                }}
              />
              <IconButton
                colorScheme="purple"
                aria-label="Remove"
                icon={<DeleteIcon />}
                onClick={() => {
                  deletePost({ postId: value, eventId: currentEvent!.id }).then(
                    () => {
                      const index = allData.findIndex(
                        (post) => post.id === parseInt(value)
                      );
                      const newData = [...allData];
                      newData.splice(index, 1);
                      setAllData(newData);
                    }
                  );
                }}
              />
            </HStack>
          );
        },
      },
    ],
    [allData]
  );

  return (
    <Page>
      <Card>
        <HStack justify="space-between">
          <Heading>Manage Feed</Heading>
          <IconButton
            aria-label="Add Post"
            icon={<AddIcon />}
            onClick={onOpen}
          />
          <UploadPostModal isOpen={isOpen} onClose={onCloseUploadModal} />
          <MediaModal
            isOpen={!!selectedPost}
            onClose={() => setSelectedPost(undefined)}
            mediaType={selectedPost?.mediaType}
            mediaUrl={selectedPost?.url}
            title={selectedPost?.title}
          />
        </HStack>
        <YCTable columns={columns} data={allData || []} />
        <Button
          isLoading={isLoading}
          disabled={!areMorePosts}
          onClick={() => {
            setCursor(allData[allData.length - 1]?.id);
            refetch();
          }}
        >
          Load More...
        </Button>
      </Card>
    </Page>
  );
};
