import {Box, Heading, HStack, IconButton, useDisclosure, useToast} from '@chakra-ui/react';
import Page from '../../components/Page/Page';
import {TableControls} from '../../components/TableControls/TableControls';
import {YCTable} from '../../components/YCTable/YCTable';
import {useEventContext} from '../../contexts/EventContext';
import Card from 'components/Card/Card';
import {
  useDeleteNotificationMessageByIdMutation,
  useGetAllNotificationMessagesForEventQuery,
  useGetAllNotificationSubscriptionsForEventQuery,
  useRefreshEventNotificationsMutation,
  useSendPushNotificationMessageMutation,
} from '../../app/api';
import CreatePushNotifcationMessageModal from './CreatePushNotifcationMessageModal';
import {skipToken} from '@reduxjs/toolkit/query/react';
import {Cell} from 'react-table';
import {PushNotificationMessage, RefreshNotificationForEventResponse} from '../../types/dto';
import {CheckIcon, DeleteIcon} from '@chakra-ui/icons';

const PushNotification = () => {
  const {currentEvent} = useEventContext();

  const {data: subscriptions, refetch: refetchSubscriptions} =
    useGetAllNotificationSubscriptionsForEventQuery(currentEvent?.id ?? skipToken);

  const {data: messages, refetch: refetchMessages} =
    useGetAllNotificationMessagesForEventQuery(currentEvent?.id ?? skipToken);

  const [sendPushNotificationMessage] = useSendPushNotificationMessageMutation();
  const [deletePushNotificationMessage] = useDeleteNotificationMessageByIdMutation();
  const [refreshPushNotificationForEvent] = useRefreshEventNotificationsMutation();

  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();


  const handleSendNotification = async (id: number) => {
    try {
      await sendPushNotificationMessage(id).unwrap();
      toast({
        title: 'Notification Sent',
        description: 'The push notification was sent successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      refetchMessages();
      refetchSubscriptions();
    } catch (error: any) {
      toast({
        title: 'Error Sending Notification',
        description: error.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRefreshNotificationSubscriptions = async () => {
    try {
      let response: RefreshNotificationForEventResponse = await refreshPushNotificationForEvent(currentEvent?.id || -1).unwrap();
      toast({
        title: 'Refreshed Notification Subscriptions',
        description: response.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error: any) {
      toast({
        title: 'Error Sending Notification',
        description: error.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const handleDeleteNotification = async (id: number) => {
    try {
      await deletePushNotificationMessage(id).unwrap();
      toast({
        title: 'Notification Deleted',
        description: 'The notification was successfully deleted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      refetchMessages();
      refetchSubscriptions();
    } catch (error: any) {
      console.error(error);
      toast({
        title: 'Error Deleting Notification',
        description: error.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const columns = [
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Body',
      accessor: 'body',
    },
    {
      Header: 'Subscription Type',
      accessor: 'subscriptionType',
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({value}: Cell<PushNotificationMessage>) => {
        return (
          <HStack>
            <IconButton
              colorScheme="green"
              aria-label="Approve"
              icon={<CheckIcon/>}
              onClick={async () => {
                await handleSendNotification(value);
              }}
            />
            <IconButton
              colorScheme="purple"
              aria-label="Remove"
              icon={<DeleteIcon/>}
              onClick={async () => {
                await handleDeleteNotification(value);
              }}
            />
          </HStack>
        );
      },
    },
  ];


  const tableData = messages?.map((message) => {
    const subscription = subscriptions?.find(
      (sub) => sub.id === message.notificationSubscriptionId
    );
    return {
      ...message,
      subscriptionType: subscription != null ? subscription.subscriptionType : undefined,
    };
  }) || [];

  return (
    <>
      <Page flexDir="row" display="flex">
        <Box padding={5} flexGrow={1} overflow="auto">
          <Card>
            <Heading>Push Notification Messages</Heading>
            <TableControls addAction={onOpen} refreshAction={handleRefreshNotificationSubscriptions}/>
          </Card>
          <Card mt={2}>
            <YCTable columns={columns} data={tableData}/>
          </Card>
        </Box>
      </Page>
      <CreatePushNotifcationMessageModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        afterSubmit={() => {
          refetchMessages();
          refetchSubscriptions();
        }}
        eventId={currentEvent?.id || -1}
      />
    </>
  );
};

export default PushNotification;
