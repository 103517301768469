import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Input,
} from '@chakra-ui/react';
import { useFormik, FormikHelpers, FormikProps, Form, Formik } from 'formik';

interface CreateModalProps {
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  header: string;
  initialValues: object;
  inputs: {
    type: string | React.FC<{ formik: FormikProps<any> }>;
    htmlFor: string;
    label: string;
  }[];
  validationSchema: any;
  onSubmit: ((
    values: object,
    formikHelpers: FormikHelpers<object>
  ) => void | Promise<any>) &
    ((values: object, { setSubmitting }: FormikHelpers<object>) => void);
}

const CreateModal: React.FC<Required<CreateModalProps>> = ({
  header,
  initialValues,
  inputs,
  onSubmit,
  isOpen,
  onOpen,
  onClose,
  validationSchema,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                {inputs.map((input) => (
                  <FormControl
                    key={input.htmlFor}
                    isInvalid={
                      (formik.errors as any)[input.htmlFor] &&
                      (formik.touched as any)[input.htmlFor]
                    }
                  >
                    <FormLabel htmlFor={input.htmlFor}>{input.label}</FormLabel>
                    {typeof input.type == 'string' ? (
                      <Input
                        type={input.type}
                        id={input.htmlFor}
                        {...formik.getFieldProps(input.htmlFor)}
                      />
                    ) : (
                      <input.type formik={formik} />
                    )}
                  </FormControl>
                ))}
                <Button
                  disabled={!(formik.isValid && formik.dirty)}
                  mt={4}
                  color="blue.500"
                  isLoading={formik.isSubmitting}
                  type="submit"
                >
                  Create
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateModal;
