import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { BasketballLoader } from 'components/BasketballLoader/BasketballLoader';
import { useEventContext } from 'contexts/EventContext';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { EventRoleType, OrganizationRoleType } from 'types/dto';
import { useLogoutMutation, useRefreshMutation } from '../../app/api';
import {
  logout as logoutAction,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../app/store';
import { Role } from '../../types';

//Current user should be changed to obj when actual users will need to be made
//Sign up, forgot password, etc.. can be added here when its needed.
interface AuthContextInterface {
  logout: Function;
  error: FetchBaseQueryError | SerializedError | undefined;
  loading: boolean;
}

const defaultAuthContext = {
  logout: () => {},
  error: undefined,
  loading: false,
};

const AuthContext =
  React.createContext<AuthContextInterface>(defaultAuthContext);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const userStore = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [refresh, { isUninitialized, isLoading, data, error }] =
    useRefreshMutation();
  const [logout, { isLoading: isLoggingOut, data: loggedOutResult }] =
    useLogoutMutation();

  useEffect(() => {
    if (!userStore.token && isUninitialized) {
      refresh();
    }
  }, [isUninitialized, userStore.token, refresh]);

  async function doLogout() {
    await logout();
    dispatch(logoutAction({}));
  }

  return (
    <AuthContext.Provider
      value={{
        logout: doLogout,
        error,
        loading: isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function RequireAuth() {
  const { error } = useAuth();
  const location = useLocation();
  const userStore = useSelector((state: RootState) => state.user);

  if (!userStore.token && !error) {
    return <BasketballLoader />;
  }

  return userStore.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}

export interface RequireAccessProps {
  fallbackRoute?: string;
  requires: Role;
}

export const RequireAccess: React.FC<RequireAccessProps> = ({
  requires,
  fallbackRoute,
}) => {
  const { canPerformEventAction } = useEventContext();
  const { error } = useAuth();
  const location = useLocation();
  const userStore = useAppSelector((store) => store.user);

  if (!userStore.token && !error) {
    return <BasketballLoader />;
  }

  return userStore.isAuthenticated && canPerformEventAction(requires) ? (
    <Outlet />
  ) : (
    <Navigate to={fallbackRoute ?? '/login'} state={{ from: location }} />
  );
};

export const canPerformAction = (
  role: EventRoleType = 'PLAYER',
  requiresRole: Role
) => {
  const map: { [key: Role]: number } = {
    EVENT_OWNER: 0,
    EVENT_ADMIN: 1,
    EVENT_STAFF: 2,
    SCORE_KEEPER: 3,
    PLAYER: 4,
  };
  return map[role] <= map[requiresRole];
};

export const canPerformOrganizationAction = (
  role: OrganizationRoleType = 'READ_ONLY',
  requiresRole: OrganizationRoleType
) => {
  const map: { [key: OrganizationRoleType]: number } = {
    ORG_OWNER: 0,
    ORG_ADMIN: 1,
    READ_WRITE: 2,
    READ_ONLY: 3,
  };
  return map[role] <= map[requiresRole];
};
