import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import {
  useCreateTeamMutation,
  useGetCentersQuery,
  useGetUsersQuery,
} from 'app/api';
import { useEventContext } from 'contexts/EventContext';
import { useState } from 'react';
import { League, LeagueEvent, User } from 'types';
import { InputActionMeta, Select } from 'chakra-react-select';
import { Center } from 'types/dto';

interface AddTeamsModalProps {
  isOpen: boolean;
  onClose: () => void;
  league: LeagueEvent;
  refetchTeams: () => void;
}

export const AddTeamsModal: React.FC<AddTeamsModalProps> = ({
  isOpen,
  onClose,
  league,
  refetchTeams,
}) => {
  const [center, setCenter] = useState<Center>();
  const [teamName, setTeamName] = useState('');
  const [newPlayers, setNewPlayers] = useState<User[]>([]);
  const [createTeam] = useCreateTeamMutation({});
  const { data: centers } = useGetCentersQuery();
  const { currentEvent } = useEventContext();
  const { data: users } = useGetUsersQuery(
    { playing: false, eventId: currentEvent!.id },
    { skip: !currentEvent || !isOpen }
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Teams</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Adding team to {currentEvent?.name} in league {league.name}
          </Text>
          <VStack>
            <Input
              placeholder="Team name"
              onChange={(e) => setTeamName(e.target.value)}
            />
            <FormControl id="center">
              <Select
                placeholder="Select center"
                onChange={(value) => {
                  if (value) {
                    setCenter(centers!.find((c) => c.id === value.value)!);
                  }
                }}
                options={
                  centers?.map((center) => ({
                    value: center.id,
                    label: `${center.name}`,
                  })) || []
                }
              />
            </FormControl>
            <HStack>
              <Heading size="sm">Add Players</Heading>
            </HStack>
            <FormControl id="player">
              <Select
                isMulti
                onChange={(value) => {
                  if (value) {
                    setNewPlayers(
                      value.map((v) => users!.find((u) => u.id === v.value)!)
                    );
                  }
                }}
                placeholder="Select player"
                options={
                  users?.map((user) => ({
                    value: user.id,
                    label: `${user.firstName} ${user.lastName} ${user.center} ${user.email}`,
                  })) || []
                }
              />
            </FormControl>
            <HStack w="100%" justify="right">
              <Button
                disabled={!teamName || !center || !newPlayers.length}
                color="white"
                bgColor={useColorModeValue('orange.500', 'blue.500')}
                onClick={() => {
                  createTeam({
                    eventId: currentEvent!.id,
                    leagueId: league.id,
                    name: teamName,
                    centerId: center!.id,
                    players: newPlayers.map((p) => p.id),
                  })
                    .unwrap()
                    .then(() => {
                      onClose();
                      refetchTeams();
                    });
                }}
              >
                Save
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
