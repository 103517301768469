import CreateModal from '../../components/CreateModal';
import * as yup from 'yup';
import {useCreateMenuForEventMutation} from '../../app/api';
import React from 'react';
import {DateTimeInput} from '../../components/DateTimeInput';
import {CreateMenuEvent, Menu} from '../../types/dto';

interface CreateMenuProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  afterSubmit: () => void;
  eventId: number
}

const CreateMenuModal: React.FC<CreateMenuProps> = ({
                                                      isOpen,
                                                      onClose,
                                                      onOpen,
                                                      afterSubmit,
                                                      eventId,
                                                    }) => {
  const [createMenu] = useCreateMenuForEventMutation();


  return (
    <CreateModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      header={'Create Menu'}
      initialValues={{
        title: '',
        description: '',
        startTime: new Date(),
        endTime: new Date()
      }}
      inputs={[
        {
          htmlFor: 'title',
          label: 'Title',
          type: 'text',
        },
        {
          htmlFor: 'startTime',
          label: 'Start Time',
          type: ({formik}) => (<DateTimeInput date={formik.values.startTime} onDateChange={(newDate) => {
            if (!isNaN(newDate.getTime())) {
              formik.setFieldValue('startTime', newDate);
            }
          }}/>)
        },
        {
          htmlFor: 'endTime',
          label: 'End Time',
          type: ({formik}) => (<DateTimeInput date={formik.values.endTime} onDateChange={(newDate) => {
            if (!isNaN(newDate.getTime())) {
              formik.setFieldValue('endTime', newDate);
            }
          }}/>)
        }
      ]}
      validationSchema={yup.object().shape({
        title: yup.string().required('Name is required'),
        startTime: yup.date().required('Start Time is required'),
        endTime: yup.date().required('End Time is required')
      })}
      onSubmit={async (values, formik) => {
        const vals = values as CreateMenuFormValues;
        const menu: Partial<Menu> = {
          title: vals.title,
          description: vals.description,
          startTime: vals.startTime,
          endTime: vals.endTime
        }
        await createMenu({
          eventId: eventId,
          menu: menu as CreateMenuEvent,
        }).unwrap();
        formik.setSubmitting(false);
        onClose();
        afterSubmit();
      }}
    />
  );
};

interface CreateMenuFormValues {
  title: string;
  description: string;
  startTime: Date;
  endTime: Date;
}

export default CreateMenuModal;
