import React from 'react';
import {
  Button,
  Collapse,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  useColorModeValue,
  useToast,
  VStack
} from '@chakra-ui/react';
import {AddIcon, CloseIcon, TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons';
import Card from '../../../components/Card/Card';
import {useCreateSubEventInBulkMutation, useGetSubEventsForEventQuery} from '../../../app/api';
import {FieldArray, Formik} from 'formik';
import {SubEvent} from '../../../types/dto';
import {DateTimeInput} from '../../../components/DateTimeInput';

interface SubEventCreationSectionProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  eventId: number;
}


export const SubEventCreationSection: React.FC<SubEventCreationSectionProps> =
  ({isOpen, onClose, onOpen, eventId}) => {
    const {data: subEvents} = useGetSubEventsForEventQuery(eventId);
    const [createSubEvents] = useCreateSubEventInBulkMutation();
    const saveButtonColor = useColorModeValue('orange', 'blue');
    const toast = useToast();

    return (
      <Card>
        <HStack w="100%">
          <Heading as="h3" size="md">
            Sub-Event Creation
          </Heading>
          {isOpen ? (
            <TriangleUpIcon onClick={onClose}/>
          ) : (
            <TriangleDownIcon onClick={onOpen}/>
          )}
        </HStack>
        <Collapse in={isOpen}>
          <Formik
            initialValues={{subEvents: subEvents || []}}
            onSubmit={(values, {setSubmitting}) => {
              setSubmitting(true);
              createSubEvents({
                eventId: eventId,
                subEvents: values.subEvents
              }).then(() => {
                toast({
                  description: 'Create Successful',
                  status: 'success',
                  isClosable: true,
                  duration: 5000
                });
              })
              setSubmitting(false);
            }}
            enableReinitialize={true}
          >
            {({values, isSubmitting, submitForm}) => (
              <FieldArray name="subEvents">
                {({push, remove, replace}) => (
                  <VStack>
                    {values.subEvents.map((subEvent: Partial<SubEvent>, index) => (
                      <HStack key={`subevent-${index}`} alignItems="flex-end">
                        <FormControl>
                          <FormLabel htmlFor={`subevent-title-${index}`}>
                            Sub-Event Title
                          </FormLabel>
                          <Input
                            id={`subEvent-title-${index}`}
                            onChange={(e) =>
                              replace(index, {...subEvent, title: e.target.value})
                            }
                            value={subEvent.title}
                            placeholder="Enter a Title"
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel htmlFor={`subevent-description-${index}`}>
                            Sub-Event Description
                          </FormLabel>
                          <Input
                            id={`subEvent-description-${index}`}
                            onChange={(e) =>
                              replace(index, {...subEvent, description: e.target.value})
                            }
                            value={subEvent.description}
                            placeholder="Enter a Description"
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel htmlFor={`subEvent-date-${index}`}>
                            Sub-Event Date
                          </FormLabel>
                          <DateTimeInput onDateChange={(newDate) => {
                            if (!isNaN(newDate.getTime())) {
                              replace(index, {...subEvent, time: newDate});
                            }
                          }}
                                         date={subEvent.time}
                          />
                        </FormControl>
                        <IconButton
                          icon={<CloseIcon/>}
                          onClick={() => remove(index)}
                          aria-label={'remove-subEvent'}
                        />
                      </HStack>
                    ))}
                    <HStack>
                      <Button
                        isDisabled={isSubmitting}
                        onClick={() => push({title: '', description: '', time: new Date(), eventId})}
                      >
                        Add
                        <AddIcon/>
                      </Button>
                      <Button
                        onClick={submitForm}
                        isLoading={isSubmitting}
                        colorScheme={saveButtonColor}
                      >
                        Save
                      </Button>
                    </HStack>
                  </VStack>
                )}
              </FieldArray>
            )}
          </Formik>
        </Collapse>
      </Card>
    );
  }
