import { Heading } from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useParams } from 'react-router-dom';
import { useGetCourtByIdQuery } from '../../app/api';
import Card from '../../components/Card/Card';
import Page from '../../components/Page/Page';

export const CourtViewPage: React.FC = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useGetCourtByIdQuery(
    id ? parseInt(id) : skipToken
  );
  return (
    <Page>
      <Heading>{data ? data.name : 'Court'}</Heading>
      <Card>
        <Heading>Schedule</Heading>
      </Card>
    </Page>
  );
};
