import Card from 'components/Card/Card';
import {useMemo, useState} from 'react';
import {YCTable} from '../../components/YCTable/YCTable';
import {EventRoleType, SportEventMember} from 'types/dto';
import {Select} from '@chakra-ui/react';

interface PermissionTableProps {
  data: SportEventMember[] | undefined,
  handleUpdate: (updatedSportEventMember: Partial<SportEventMember>) => void;
}

const PermissionTable: React.FC<PermissionTableProps> = ({
                                                           data,
                                                           handleUpdate
                                                         }) => {
  const [search, setSearch] = useState('');

  const handleRoleChange = (newEventRoleType: EventRoleType, member: SportEventMember) => {
    let updatedMember: SportEventMember = {
      ...member,
      eventRole: newEventRoleType
    }
    handleUpdate(updatedMember);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'eventRole',
        Cell: ({row}: { row: { original: SportEventMember } }) => (
          <Select
            value={row.original.eventRole}
            onChange={(e) => handleRoleChange(e.target.value, row.original)}
          >
            {Object.values(EventRoleType).map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </Select>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Card mt={2}>
        <YCTable columns={columns} data={data || []} search={search} setSearch={setSearch} useFlex/>
      </Card>
    </>
  );
};

export default PermissionTable;
