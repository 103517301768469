import { MenuItemOption, Skeleton } from '@chakra-ui/react';
import { useGetAllLeaguesQuery } from '../../app/api';
import { League } from '../../types';

interface LeagueOptionsProps {
  addLeague: (league: League) => void;
}

const LeagueOptions: React.FC<LeagueOptionsProps> = ({ addLeague }) => {
  const { data, error, isLoading } = useGetAllLeaguesQuery();
  return (
    <>
      {!data ? (
        <MenuItemOption>
          <Skeleton />
        </MenuItemOption>
      ) : (
        data.map((league) => (
          <MenuItemOption
            key={league.id}
            value={league.id.toString()}
            onClick={() => addLeague(league)}
          >
            {league.name}
          </MenuItemOption>
        ))
      )}
    </>
  );
};

export default LeagueOptions;
