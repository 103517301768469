import {Box, Button, Checkbox, Collapse, FormControl, Heading, HStack, Icon, StatGroup, Text, useToast, VStack,} from '@chakra-ui/react';
import {useParams} from 'react-router';
import {useCheckInPlayerMutation, useGetTeamByIdQuery, useUpdateTeamMutation} from '../../app/api';
import Page from '../../components/Page/Page';
import {skipToken} from '@reduxjs/toolkit/query/react';
import {Form, Formik} from 'formik';
import {useEffect, useMemo, useState} from 'react';
import Card from '../../components/Card/Card';
import {YCTable} from '../../components/YCTable/YCTable';
import SkeletonStats from '../../components/SkeletonStats/SkeletonStats';
import {CheckCircleIcon, InfoIcon, TriangleDownIcon, TriangleUpIcon, WarningTwoIcon,} from '@chakra-ui/icons';
import {checkTeamStatus} from '../../app/utils';
import {Cell} from 'react-table';
import {Player} from '../../types';

const TeamViewPage = () => {
  const toast = useToast();
  const [showCheckIn, setShowCheckIn] = useState(false);
  const {id} = useParams();
  const {data, error, isLoading, refetch} = useGetTeamByIdQuery(
    id ? parseInt(id) : skipToken
  );
  const [
    updateTeam,
    {data: updateTeamResult, error: updateTeamError, reset},
  ] = useUpdateTeamMutation();

  const [checkInPlayer] = useCheckInPlayerMutation();

  const playerTableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'BKMS ID',
        accessor: 'bkmsId',
      },
      {
        Header: 'Shirt Size',
        accessor: 'shirtSize',
      },
      {
        Header: 'Sweatpant Size',
        accessor: 'sweatpantSize',
      },
      {
        Header: 'Checked In',
        accessor: 'present',
        Cell: ({value, row}: Cell<Player>) => (
          <Checkbox
            isChecked={value}
            onChange={() => {
              checkInPlayer({id: row.original.id, isPresent: true}).then(() => {
                toast({
                  title: 'Success',
                  description: 'Player checked in',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });
                refetch();
              });
            }}
          />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (data && updateTeamResult) {
      reset();
      toast({
        status: 'success',
        title: 'Updated Team',
        description: `${data.name} has been updated sucessfully.`,
      });
    }
  }, [updateTeamResult, toast, data, reset]);

  useEffect(() => {
    if (updateTeamError) {
      toast({
        status: 'error',
        title: 'Failed to Update Team',
        description: updateTeamError,
      });
    }
  }, [toast, updateTeamError]);

  const checkInStatus = data ? checkTeamStatus(data) : 0;

  return (
    <Page display="flex" flexDir="row">
      <Box p={5} h="100vh" width="100vw">
        <Heading>{data?.name}</Heading>
        <VStack h="100%" spacing={2}>
          <Card>
            <HStack>
              <Icon
                as={
                  checkInStatus === 2
                    ? CheckCircleIcon
                    : checkInStatus === 1
                      ? InfoIcon
                      : WarningTwoIcon
                }
                color={
                  checkInStatus === 2
                    ? 'green.500'
                    : checkInStatus === 1
                      ? 'orange.500'
                      : 'red.500'
                }
              />
              <Text size="md">
                {(!!data && !data.players.every((player) => player.present)) ||
                showCheckIn
                  ? 'Check-In'
                  : 'Team is checked-in'}{' '}
              </Text>
              {showCheckIn ? (
                <TriangleUpIcon onClick={() => setShowCheckIn(false)}/>
              ) : (
                <TriangleDownIcon onClick={() => setShowCheckIn(true)}/>
              )}
            </HStack>
            <Collapse
              in={
                (!!data && !data.players.every((player) => player.present)) ||
                showCheckIn
              }
            >
              {data && (
                <Formik
                  initialValues={{shirtsGiven: data.shirtsGiven || false}}
                  onSubmit={
                    id
                      ? async (values, formik) => {
                        await updateTeam({id: parseInt(id), team: values});
                        formik.setSubmitting(false);
                        refetch();
                      }
                      : () => {
                      }
                  }
                >
                  {(formik) => (
                    <VStack as={Form} w="full">
                      <HStack>
                        <FormControl>
                          <Checkbox
                            isChecked={formik.values.shirtsGiven}
                            onChange={() =>
                              formik.setFieldValue(
                                'shirtsGiven',
                                !formik.values.shirtsGiven
                              )
                            }
                          >
                            Shirts Given?
                          </Checkbox>
                        </FormControl>
                      </HStack>
                      <Button
                        type="submit"
                        isLoading={formik.isSubmitting}
                        color="blue.500"
                        disabled={formik.values.shirtsGiven === (data.shirtsGiven || false)}
                      >
                        Save
                      </Button>
                    </VStack>
                  )}
                </Formik>
              )}
            </Collapse>
          </Card>
          <Card>
            <StatGroup w="100%">
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.leagueName}
                label={'League'}
              />
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.players.length}
                label={'Players'}
              />
              <SkeletonStats
                isLoading={isLoading}
                stat={`${data?.gamesWon}-${data?.gamesLost}`}
                label={'Season Record'}
              />
              <SkeletonStats
                isLoading={isLoading}
                stat={data?.rank || 'Unranked'}
                label={'Rank'}
              />
            </StatGroup>
          </Card>
          <Card>
            <YCTable columns={playerTableColumns} data={data?.players || []}/>
          </Card>
        </VStack>
      </Box>
    </Page>
  );
};

export default TeamViewPage;
