import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Input,
  useToast,
} from '@chakra-ui/react';
import { useCreatePostMutation } from 'app/api';
import { FileUploadInput } from 'components/FileUploadInput';
import { useEventContext } from 'contexts/EventContext';
import { useState } from 'react';

interface UploadPostModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UploadPostModal: React.FC<UploadPostModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { currentEvent } = useEventContext();
  const toast = useToast();
  const [createPostMutation] = useCreatePostMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const handleUpload = async () => {
    if (selectedFile && currentEvent) {
      try {
        setIsLoading(true);
        const file_form = new FormData();
        file_form.append('file', selectedFile);
        const resp = await createPostMutation({
          title,
          description,
          eventId: currentEvent.id,
          fileType: selectedFile.type,
          fileName: selectedFile.name,
          timestamp: selectedFile.lastModified.toString(),
        }).unwrap();

        await fetch(resp.url, {
          method: 'PUT',
          body: selectedFile,
          headers: {
            'Content-Type': selectedFile.type,
          },
        });

        toast({
          title: 'Post uploaded',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose();
        setIsLoading(false);
      } catch (error) {
        toast({
          title: 'Error uploading post',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Post</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Input
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Input
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <FileUploadInput
              handleUpload={handleUpload}
              selectedFile={selectedFile}
              accept="image/*,video/*"
              setSelectedFile={setSelectedFile}
              isLoading={isLoading}
            />
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
