import { Heading, useColorModeValue } from "@chakra-ui/react";
import { ResponsiveBar } from "@nivo/bar";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetTeamsForEventQuery } from "app/api";
import { checkTeamStatus } from "app/utils";
import Card from "components/Card/Card";
import { Team } from "types";

interface SportEventTeamStatusPanelProps {
    eventId: number;
    teams?: Team[]
}

export const SportEventTeamStatusPanel: React.FC<SportEventTeamStatusPanelProps> = ({ eventId, teams }) => {
    return (
        <Card h="250px">
        <Heading>Team Status</Heading>
        <ResponsiveBar
          data={
            teams
              ? [
                  teams.reduce(
                    (prev, curr) => {
                      const status = checkTeamStatus(curr);
                      if (status === 2) {
                        prev['Present']++;
                      } else if (status === 1) {
                        prev['Present With Warning']++;
                      } else {
                        prev['Not Present']++;
                      }
                      return prev;
                    },
                    {
                      'Not Present': 0,
                      'Present With Warning': 0,
                      Present: 0,
                      event: eventId,
                    }
                  ),
                ]
              : []
          }
          keys={['Present', 'Present With Warning', 'Not Present']}
          animate={true}
          indexBy="event"
          layout="horizontal"
          theme={{
            textColor: useColorModeValue('#000', '#fff'),
          }}
          colors={['#38A169', '#ECC94B', '#E53E3E']}
          margin={{ top: 0, right: 5, bottom: 50, left: 5 }}
          axisBottom={null}
          axisLeft={null}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom',
              direction: 'row',
              itemWidth: 100,
              itemsSpacing: 50,
              itemHeight: 20,
              translateY: 30,
            },
          ]}
        />
      </Card>
    );
}