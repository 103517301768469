import { EditIcon } from '@chakra-ui/icons';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useGetMembersForOrganizationQuery } from 'app/api';
import { useAppSelector } from 'app/store';
import { YCTable } from 'components/YCTable/YCTable';
import { canPerformOrganizationAction } from 'contexts/AuthContext/AuthContext';
import { useOrganizationContext } from 'contexts/OrganizationContext';
import { useMemo, useState } from 'react';
import { Cell } from 'react-table';
import { useDebounce } from 'react-use';
import { OrgMember } from 'types/dto';
import { InviteMemberModal } from './InviteMemberModal';

export type OrganizationMembersTableProps = {};

export const OrganizationMembersTable: React.FC<
  OrganizationMembersTableProps
> = ({}) => {
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [debouncedSearch, setDebouncedSearch] = useState<string | undefined>(
    undefined
  );
  const [, cancel] = useDebounce(() => setDebouncedSearch(search), 700, [
    search,
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 10,
  });
  const { organizationUser } = useAppSelector((store) => store.user);
  const { currentOrg } = useOrganizationContext();
  const { data, isLoading } = useGetMembersForOrganizationQuery(
    currentOrg
      ? {
          organizationId: currentOrg.id,
          search: debouncedSearch,
          ...pageState,
        }
      : skipToken
  );
  const colunms = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'organizationRole',
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ row }: Cell<OrgMember>) => {
          return (
            <IconButton
              variant="ghost"
              aria-label="edit"
              icon={<EditIcon />}
              size="sm"
              disabled={
                !canPerformOrganizationAction(
                  organizationUser?.organizationRole || 'READ_ONLY',
                  'ORG_ADMIN'
                ) || row.original.organizationRole == 'ORG_OWNER'
              }
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <YCTable
        columns={colunms}
        data={data?.items || []}
        onBottomReached={(element) => console.log(element)}
        title={currentOrg?.name}
        addAction={onOpen}
        search={search}
        setSearch={setSearch}
        isLoading={isLoading}
      />
      <InviteMemberModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
