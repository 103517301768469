import {
  VStack,
  HStack,
  Divider,
  Badge,
  useColorModeValue,
  forwardRef,
  StackProps,
  Text,
  TextProps,
  Flex,
} from '@chakra-ui/react';
import { useDraggable } from '@dnd-kit/core';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetLeagueByIdQuery } from 'app/api';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { GameView } from 'types';
import { formatTime } from 'utils/GameUtils';
import { customTheme } from '../../theme';
import {
  timelineProgramLightPlayedTheme,
  timelineProgramDarkPlayedTheme,
  timelineProgramLightTheme,
  timelineProgramDarkTheme,
} from './timelineTheme';

interface GameBoxProps extends StackProps {
  game: GameView;
  hover: boolean;
  editing?: boolean;
}

export const GameBox = ({
  game,
  hover,
  editing,
  ...stackProps
}: GameBoxProps) => {
  const playedTheme = useColorModeValue(
    timelineProgramLightPlayedTheme(hover),
    timelineProgramDarkPlayedTheme(hover)
  );
  const unplayedTheme = useColorModeValue(
    timelineProgramLightTheme(hover),
    timelineProgramDarkTheme(hover)
  );
  const color = useColorModeValue('orange', 'blue');

  const gradient = useMemo(() => {
    return `linear(to-r, ${customTheme.colors[color][300]}, ${customTheme.colors[color][400]}, ${customTheme.colors[color][500]})`;
  }, [game.played, color]);

  const bgColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.100');

  const { data: league } = useGetLeagueByIdQuery(game.leagueId ?? skipToken);

  const won =
    game.homeTeamScore == game.awayTeamScore
      ? 'draw'
      : game.homeTeamScore > game.awayTeamScore
      ? 'home'
      : 'away';

  const getStyle = (teamName?: string) => {
    const style: TextProps = {
      width: '100%',
      mb: 0,
      fontSize: 'sm',
      noOfLines: 1,
      textOverflow: 'ellipsis',
      color: 'gray.500',
    };
    if (teamName && won !== 'draw') {
      if (hover) {
        style.color = '#f5f5f5';
      } else {
        if (teamName === won) {
          style.color = playedTheme.green[500];
          style.fontWeight = 'bold';
        } else {
          // style.color = playedTheme.red[300];
        }
      }
    } else if (hover) {
      style.color = '#f5f5f5';
    } else if (game.played && won === 'draw') {
      style.color = 'yellow.500';
      style.fontWeight = 'bold';
    }
    return style;
  };

  const getGlow = () => {
    const base = '0 0 0 2px ';
    if (game.played) {
      return base + '#68d391';
    } else if (game.scoreKeeperId) {
      return base + '#f6e05e';
    } else {
      return 'none';
    }
  };

  const { attributes, listeners, setNodeRef, active, isDragging } =
    useDraggable({
      id: game.id,
      disabled: !editing,
    });

  return (
    <Flex
      as={motion.div}
      layout
      h="full"
      py={1}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      {...stackProps}
    >
      <VStack
        justify="center"
        opacity={isDragging ? '0.3' : '1'}
        spacing={0}
        w="full"
        h="full"
        paddingInline={5}
        borderRadius="xl"
        bgGradient={hover ? gradient : 'none'}
        backgroundColor={bgColor}
        // glow green
        boxShadow={getGlow()}
      >
        <HStack w="full" justify="space-around">
          <VStack spacing={0} alignItems="flex-start" minW={0}>
            <Text {...getStyle('home')}>
              {game.homeTeamDrawId || game.homeTeamId}: {game.homeTeamName}
            </Text>
            <Text {...getStyle('away')}>
              {game.awayTeamDrawId || game.awayTeamId}: {game.awayTeamName}
            </Text>
          </VStack>
          <HStack h="100%" flex={1} justify="flex-end">
            <Divider orientation="vertical" />
            <VStack spacing={0} alignItems="flex-start">
              <Text {...getStyle('home')}>{game.homeTeamScore}</Text>
              <Text {...getStyle('away')}>{game.awayTeamScore}</Text>
            </VStack>
          </HStack>
        </HStack>
        <HStack w="full" justify="flex-start">
          <Badge colorScheme={league?.name === 'K1' ? 'orange' : 'blue'}>
            {league?.name}
          </Badge>
          <Text {...getStyle()} fontSize="xs">
            {formatTime(game.startTime)} - {formatTime(game.endTime)}
          </Text>
          {game.verified && (
            <Badge colorScheme="green" fontSize="xs">
              Verified
            </Badge>
          )}
        </HStack>
      </VStack>
    </Flex>
  );
};
