import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useChangePasswordMutation } from 'app/api';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useQuery } from 'utils/useQuery';

interface ResetPasswordProps {}

export const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const toast = useToast();
  const passwordsMatch = password === confirmPassword;
  const primary = useColorModeValue('orange', 'blue');
  const bgColor = useColorModeValue('gray.50', 'gray.800');

  if (!query.has('token')) {
    return <Navigate to="/login" />;
  }

  return (
    <Flex bg={bgColor} minH={'100vh'} align={'center'} justify={'center'}>
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Enter new password
        </Heading>
        <FormControl id="password" isRequired>
          <FormLabel>New Password</FormLabel>
          <Input
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <FormControl
          id="confirm-password"
          isRequired
          isInvalid={!passwordsMatch}
        >
          <FormLabel>Confirm Password</FormLabel>
          <Input
            value={confirmPassword}
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {!passwordsMatch && (
            <FormErrorMessage>Passwords Must Match</FormErrorMessage>
          )}
        </FormControl>
        <Stack spacing={6}>
          <Button
            disabled={!password || !confirmPassword || !passwordsMatch}
            bg={primary + '.500'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            onClick={async () => {
              await changePassword({
                password,
                confirmPassword,
                token: query.get('token') || '',
              });
              toast({
                title: 'Success',
                description: 'Your password has been changed!',
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
              navigate('/login');
            }}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
};
