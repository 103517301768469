import { InfoIcon } from '@chakra-ui/icons';
import {
  Button,
  Heading,
  HStack,
  IconButton,
  Stack,
  StackProps,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useEventContext } from 'contexts/EventContext';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useFinishGameMutation,
  useGetGameStatsQuery,
  useStartGameMutation,
  useUpdatePlayerStatsMutation,
} from '../../app/api';
import { useAppSelector } from '../../app/store';
import Card from '../../components/Card/Card';
import Page from '../../components/Page/Page';
import { PlayerStats } from '../../types';
import { FinishGameButton } from './FinishGameButton';
import { ScoringInfoModal } from './ScoringInfoModal';
import { TeamStats } from './TeamStats';

export const Scoring = () => {
  const { canPerformEventAction } = useEventContext();
  const { gameId } = useParams();
  // const [selectedPlayerShots, setSelectedPlayerShots] = useState<Shot[]>([]);
  const { data: gameStatsData, refetch: refetchGameStats } =
    useGetGameStatsQuery(gameId ? parseInt(gameId) : skipToken);
  const [updatePlayerStats] = useUpdatePlayerStatsMutation();
  const [startGameMutation] = useStartGameMutation();
  const [finishGameMutation] = useFinishGameMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPlayer, setSelectedPlayer] = useState<number | null>(null);
  const [statsChanging, setStatsChanging] = useState<number[]>([]);
  const buttonColor = useColorModeValue('orange', 'blue');
  const userStore = useAppSelector((store) => store.user);

  const onPlayerClick = useCallback(
    (playerId: number) => {
      if (!selectedPlayer) {
        setSelectedPlayer(playerId);
      } else if (selectedPlayer === playerId) {
        setSelectedPlayer(null);
      }
    },
    [setSelectedPlayer, selectedPlayer]
  );

  const playerNameStyler = (id: number, name: string): StackProps => {
    if (id === selectedPlayer) {
      return {
        bg: 'blue.500',
        color: 'white',
      };
    } else return {};
  };

  const onStatsChange = (
    id: number,
    {
      onePoints,
      twoPoints,
      threePoints,
      rebounds,
      blocks,
      assists,
      steals,
    }: PlayerStats
  ) => {
    setStatsChanging([...statsChanging, id]);
    updatePlayerStats({
      id,
      stats: {
        onePoints,
        twoPoints,
        threePoints,
        rebounds,
        blocks,
        assists,
        steals,
      },
    })
      .then(() => {
        return refetchGameStats();
      })
      .then(() => {
        setStatsChanging(statsChanging.filter((i) => i !== id));
      });
  };

  const editable = gameStatsData
    ? gameStatsData.game.played
      ? canPerformEventAction('EVENT_ADMIN')
      : canPerformEventAction('SCORE_KEEPER')
    : false;
  return (
    <Page>
      <Card h="100%">
        <HStack align="center" justifyContent="space-between">
          <VStack>
            <Heading>Scoring</Heading>
            <Text>Game ID: {gameStatsData?.game.id}</Text>
          </VStack>
          <HStack>
            {gameStatsData?.scoreKeeperName && (
              <Text>
                ScoreKeeper: {gameStatsData.scoreKeeperName} (ID:{' '}
                {gameStatsData.game.scoreKeeperId})
              </Text>
            )}
            {gameStatsData?.homeTeam.length === 0 &&
              gameStatsData?.awayTeam.length === 0 &&
              gameStatsData?.game.homeTeamId &&
              gameStatsData.game.awayTeamId && (
                <Button
                  colorScheme={buttonColor}
                  onClick={() => {
                    startGameMutation({ id: gameStatsData.game.id }).then(() =>
                      refetchGameStats()
                    );
                  }}
                >
                  Start Game
                </Button>
              )}
            {gameStatsData &&
              !gameStatsData.game.played &&
              gameStatsData.homeTeam.length > 0 &&
              gameStatsData.awayTeam.length > 0 && (
                <FinishGameButton
                  isDisabled={!editable}
                  colorScheme={buttonColor}
                  onClick={() => {
                    finishGameMutation({ id: gameStatsData.game.id }).then(() =>
                      refetchGameStats()
                    );
                  }}
                />
              )}
            {canPerformEventAction('EVENT_ADMIN') && (
              <Button>Override Input</Button>
            )}
            <IconButton
              bg="transparent"
              icon={<InfoIcon />}
              aria-label={'about-scoring'}
              onClick={onOpen}
            />
          </HStack>
        </HStack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          h={{ base: '100%' }}
          align="center"
        >
          {/* <BasketballCourt
            w={{ base: '100%', md: '50%' }}
            shots={selectedPlayerShots}
            scale={10}
          /> */}
          {gameStatsData && (
            <VStack w={{ base: '100%' }} align="center">
              <TeamStats
                editable={editable}
                id={gameStatsData.game.homeTeamId}
                name={gameStatsData.game.homeTeamName}
                drawId={gameStatsData.game.homeTeamDrawId}
                score={gameStatsData.game.homeTeamScore}
                won={
                  gameStatsData.game.homeTeamScore >
                  gameStatsData.game.awayTeamScore
                }
                players={gameStatsData?.homeTeam}
                updatingPlayerIds={statsChanging}
                onStatsChange={onStatsChange}
                onPlayerClick={onPlayerClick}
                playerNameStyler={playerNameStyler}
                isHome={true}
              />
              <TeamStats
                editable={editable}
                id={gameStatsData.game.awayTeamId}
                name={gameStatsData.game.awayTeamName}
                drawId={gameStatsData.game.awayTeamDrawId}
                score={gameStatsData.game.awayTeamScore}
                won={
                  gameStatsData.game.awayTeamScore >
                  gameStatsData.game.homeTeamScore
                }
                players={gameStatsData?.awayTeam}
                onStatsChange={onStatsChange}
                onPlayerClick={onPlayerClick}
                playerNameStyler={playerNameStyler}
                isHome={false}
              />
            </VStack>
          )}
        </Stack>
      </Card>
      <ScoringInfoModal isOpen={isOpen} onClose={onClose} />
    </Page>
  );
};
