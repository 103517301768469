import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Heading,
  HStack,
  Link,
} from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { addMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  useGetCourtsForEventQuery,
  useGetGameByIdQuery,
  useUpdateGameMutation,
} from '../app/api';
import { TimeInput } from './TimeInput';

interface PlayoffGameModalProps {
  isOpen: boolean;
  onClose: () => void;
  eventId: number;
  gameId: number;
}
export const PlayoffGameModal: React.FC<PlayoffGameModalProps> = ({
  isOpen,
  onClose,
  eventId,
  gameId,
}) => {
  const { data: courts } = useGetCourtsForEventQuery(eventId);
  const { data: game, isLoading: gameLoading } = useGetGameByIdQuery(
    isOpen ? gameId : skipToken
  );

  const [newCourtId, setNewCourtId] = useState<number | undefined>(
    game?.courtId
  );
  const [newTime, setNewTime] = useState<Date>(game?.startTime || new Date());
  const [updateGame, { isLoading: updateLoading }] = useUpdateGameMutation();

  useEffect(() => {
    if (game) {
      setNewCourtId(game.courtId);
      setNewTime(new Date(game.startTime));
    }
  }, [game]);

  const submitForm = () => {
    updateGame({
      id: gameId,
      game: {
        courtId: newCourtId,
        startTime: newTime,
        endTime: addMinutes(newTime, 20),
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Heading size="md">Playoff Game</Heading>
            <Link as={RouterLink} to={`scoringV2/${gameId}`}>
              <Button>Score</Button>
            </Link>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        {gameLoading ? (
          <Spinner />
        ) : (
          <ModalBody>
            <Heading size="sm" mb="2" textAlign="center">
              {game?.homeTeamName} vs.{' '}
              {game?.awayTeamName ? game.awayTeamName : '-----'}
            </Heading>
            <FormControl>
              <FormLabel>Change Court</FormLabel>
              <Select
                value={newCourtId}
                onChange={(e) => setNewCourtId(parseInt(e.target.value))}
              >
                {courts?.map((court) => (
                  <option key={court.id} value={court.id}>
                    {court.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Change Time</FormLabel>
              <TimeInput date={newTime} onTimeChange={(t) => setNewTime(t)} />
            </FormControl>
          </ModalBody>
        )}
        <ModalFooter>
          <Button variantColor="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={updateLoading}
            variantColor="green"
            onClick={submitForm}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
