import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';
import { useInviteMemberToOrganizationMutation } from 'app/api';
import { useOrganizationContext } from 'contexts/OrganizationContext';
import { useState } from 'react';
import { OrganizationRoleType } from 'types/dto';

export type InviteMemberModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const InviteMemberModal: React.FC<InviteMemberModalProps> = ({
  onClose,
  isOpen,
}) => {
  const { currentOrg } = useOrganizationContext();
  const [inviteMember, { data: inviteSuccessful }] =
    useInviteMemberToOrganizationMutation();
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<OrganizationRoleType>('READ_ONLY');

  const onSubmit = async () => {
    if (currentOrg != null) {
      await inviteMember({
        email: email,
        organizationId: currentOrg.id,
        role: role,
      });
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite Member</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(ev) => setEmail(ev.target.value)}
            />
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Role</FormLabel>
            <Select>
              <option value={'ORG_ADMIN'}>Admin</option>
              <option value={'READ_WRITE'}>Read/Write</option>
              <option value={'READ_ONLY'}>Read Only</option>
            </Select>
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          <Button
            disabled={!currentOrg || !email}
            onClick={onSubmit}
            marginY={2}
          >
            Invite
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
