import React, { useState, useRef, useEffect } from 'react';
import {
  Checkbox,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Image,
  Stack,
  chakra,
  useColorModeValue,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { PasswordField } from 'pages/login/PasswordField';
import { useAuth } from 'contexts/AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useLoginMutation } from '../../app/api';
import { useAppSelector } from '../../app/store';

const Login = () => {
  const userStore = useAppSelector((state) => state.user);
  const passwordRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [login, { data, isLoading, error }] = useLoginMutation();
  const navigate = useNavigate();
  const toast = useToast();

  // useEffect(() => {
  //     logout()
  // }, [logout])

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (emailRef.current && passwordRef.current) {
        let success = await login({
          email: emailRef?.current?.value,
          password: passwordRef?.current?.value,
        }).unwrap();
        if (success) navigate('/');
      }
    } catch (e: any) {
      toast({
        title: 'Error',
        description: e.data.message,
        status: 'error',
        duration: 5000,
      })
    }
  };

  return (
    <Stack minH="100vh" direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <Heading fontSize={'2xl'}>Sign in to your account</Heading>
          <chakra.form onSubmit={handleSubmit}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                ref={emailRef}
                name="email"
                type="email"
                autoComplete="email"
                required
              />
              {error && <FormErrorMessage>{error}</FormErrorMessage>}
            </FormControl>
            <PasswordField ref={passwordRef} />
            <Stack spacing={6}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}
              >
                <Checkbox>Remember me</Checkbox>
                <Link as={RouterLink} color="blue.500" to="/forgot">
                  Forgot password?
                </Link>
              </Stack>
              <Button
                colorScheme="blue"
                variant="solid"
                type="submit"
                isLoading={isLoading}
              >
                Sign in
              </Button>
            </Stack>
          </chakra.form>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          display={{ base: 'none', md: 'block' }}
          alt={'Login Image'}
          objectFit={'cover'}
          src={process.env.PUBLIC_URL + 'logo_full.png'}
        />
      </Flex>
    </Stack>
  );
};

export default Login;
