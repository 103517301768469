import {Badge, Box, Link, useDisclosure} from '@chakra-ui/react';
import {useGetTeamsForEventQuery} from '../../app/api';
import Page from '../../components/Page/Page';
import {Link as RouterLink} from 'react-router-dom';
import {YCTable} from '../../components/YCTable/YCTable';
import {useCallback, useMemo, useState} from 'react';
import {skipToken} from '@reduxjs/toolkit/query/react';
import {useEventContext} from '../../contexts/EventContext';
import CreateTeamModal from './CreateTeamsModal';
import {Cell} from 'react-table';
import {Team} from '../../types';
import {checkTeamStatus} from '../../app/utils';
import Card from 'components/Card/Card';

const Teams = ({}) => {
  const [search, setSearch] = useState('');
  const {currentEvent} = useEventContext();
  const {data, error, isLoading} = useGetTeamsForEventQuery(
    currentEvent?.id ?? skipToken
  );
  const {isOpen, onOpen, onClose} = useDisclosure();

  const status = useCallback(checkTeamStatus, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'League',
        accessor: 'leagueName',
      },
      {
        Header: 'Check In Status',
        Cell: ({row}: Cell<Team>) => {
          const checkInStatus = status(row.original);
          return (
            <Badge
              colorScheme={
                checkInStatus === 2
                  ? 'green'
                  : checkInStatus === 1
                    ? 'orange'
                    : 'red'
              }
            >
              {checkInStatus === 2
                ? 'Checked In'
                : checkInStatus === 1
                  ? 'Incomplete'
                  : 'Not Present'}
            </Badge>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({value}: Cell) => (
          <Link color="blue.500" as={RouterLink} to={`${value}`}>
            View
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <Page flexDir="row" display="flex" maxH="100%">
      <Box
        padding={5}
        display="flex"
        flexDir="column"
        flexGrow={1}
        overflow="auto"
        maxH="100vh"
      >
        <Card marginY="2" overflowY="scroll">
          <YCTable
            title={'Teams'}
            columns={columns}
            data={data || []}
            search={search}
            setSearch={setSearch}
            addAction={onOpen}
          />
        </Card>
        <CreateTeamModal onOpen={onOpen} onClose={onClose} isOpen={isOpen}/>
      </Box>
    </Page>
  );
};

export default Teams;
