import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAssumeOrgUserMutation, useGetOrganizationByIdQuery } from 'app/api';
import { useAppDispatch, useAppSelector } from 'app/store';
import { BasketballLoader } from 'components/BasketballLoader/BasketballLoader';
import React, { useContext, useEffect, useState } from 'react';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Organization } from 'types/dto';
import { useAuth } from './AuthContext/AuthContext';

interface OrganizationContextVale {
  currentOrg: Organization | null;
  setCurrentOrg: (org: Organization | null, navigate: boolean) => void;
}

const OrganizationContext = React.createContext<OrganizationContextVale>({
  currentOrg: null,
  setCurrentOrg: () => {},
});

export const useOrganizationContext = () => useContext(OrganizationContext);

export const OrganizationContextProvider: React.FC = ({ children }) => {
  const auth = useAuth();
  const [currentOrg, setCurrentOrg] = React.useState<Organization | null>(null);
  const navigate = useNavigate();

  const updateCurrentOrg = (
    org: Organization | null,
    shouldNavigate: boolean
  ) => {
    setCurrentOrg(org);
    if (shouldNavigate) {
      if (org) {
        navigate(`/organizations/${org.id}`);
      }
    }
  };

  return (
    <OrganizationContext.Provider
      value={{ currentOrg, setCurrentOrg: updateCurrentOrg }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const RequireOrganization = () => {
  const { orgId } = useParams();
  const userStore = useAppSelector((store) => store.user);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { currentOrg, setCurrentOrg } = useOrganizationContext();
  const location = useLocation();
  const [authorized, setAuthorized] = useState(false);
  const { data, isLoading, error, isSuccess } = useGetOrganizationByIdQuery(
    !currentOrg && orgId && authorized ? parseInt(orgId) : skipToken
  );

  useEffect(() => {
    if (userStore.isAuthenticated && !userStore.organizationUser) {
      // Not signed into orgUser
      logout();
    }
    if (orgId && userStore.organizationUser) {
      if (userStore.organizationUser.organizationId == Number.parseInt(orgId)) {
        setAuthorized(true);
      } else {
        console.log('Not logged in');
        navigate('/forbidden');
      }
    }
  }, [
    userStore.organizationUser,
    userStore.isAuthenticated,
    orgId,
    setCurrentOrg,
    logout,
  ]);

  useEffect(() => {
    if (!currentOrg && data) {
      setCurrentOrg(data, false);
    }
  }, [currentOrg, data, setCurrentOrg]);

  if (isLoading || (data && !currentOrg) || !authorized) {
    return <BasketballLoader />;
  }

  return currentOrg && !error ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
};
