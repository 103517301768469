import { Heading, Box, Link, useDisclosure } from '@chakra-ui/react';
import {
  useGetAllLeaguesQuery,
  useGetLeaguesForEventQuery,
} from '../../app/api';
import Page from '../../components/Page/Page';
import { Link as RouterLink } from 'react-router-dom';
import { TableControls } from '../../components/TableControls/TableControls';
import { YCTable } from '../../components/YCTable/YCTable';
import { useMemo } from 'react';
import CreateLeagueModal from './CreateLeagueModal';
import { useEventContext } from '../../contexts/EventContext';
import Card from 'components/Card/Card';

const Leagues = ({}) => {
  const { currentEvent } = useEventContext();
  const { data, error, isLoading } = useGetAllLeaguesQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ value }: { value: string }) => (
          <Link color="blue.500" as={RouterLink} to={`${value}`}>
            View
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Page flexDir="row" display="flex">
        <Box p={5} flexGrow={1} overflow="auto">
          <Card>
            <Heading>Leagues</Heading>
            <TableControls addAction={onOpen} />
          </Card>
          <Card mt={2}>
            <YCTable columns={columns} data={data || []} />
          </Card>
        </Box>
      </Page>
      <CreateLeagueModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </>
  );
};

export default Leagues;
