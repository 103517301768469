import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Button,
  Collapse,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { FileUploadInput } from 'components/FileUploadInput';
import { useRef, useState } from 'react';
import { FaFile } from 'react-icons/fa';
import {
  useGetLeaguesForEventQuery,
  useProcessEventDataMutation,
  useUploadEventDataMutation,
} from '../../../app/api';
import Card from '../../../components/Card/Card';

interface LeagueInfoSectionProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  eventId: number;
}

export const LeagueInfoSection: React.FC<LeagueInfoSectionProps> = ({
  isOpen,
  onClose,
  onOpen,
  eventId,
}) => {
  const { data: leagues } = useGetLeaguesForEventQuery(eventId);
  const [uploadEventDataMutation] = useUploadEventDataMutation();
  const [processEventData] = useProcessEventDataMutation();
  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const handleUpload = () => {
    if (selectedFile) {
      const file_form = new FormData();
      file_form.append('file', selectedFile);
      uploadEventDataMutation({ file: file_form, eventId })
        .unwrap()
        .then(({ fileName }) => processEventData({ eventId, fileName }))
        .then(() => {
          toast({
            title: 'Success',
            description: 'Data uploaded successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Card>
      <HStack w="100%">
        <Heading as="h3" size="md">
          League Information
        </Heading>
        {/* <Button>Batch Create</Button> */}
        {isOpen ? (
          <TriangleUpIcon onClick={onClose} />
        ) : (
          <TriangleDownIcon onClick={onOpen} />
        )}
      </HStack>
      <Collapse in={isOpen}>
        <FileUploadInput
          handleUpload={handleUpload}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          label={'Upload Player Information (.csv file)'}
          accept=".csv"
        />
      </Collapse>
    </Card>
  );
};
