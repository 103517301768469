import CreateModal from '../../components/CreateModal';
import * as yup from 'yup';
import { Select } from '@chakra-ui/react';
import { useGetAllEventsQuery, useGetAllLeaguesQuery } from '../../app/api';

interface CreateTeamModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const CreateTeamModal: React.FC<CreateTeamModalProps> = ({
  isOpen,
  onClose,
  onOpen,
}) => {
  const { data: eventData } = useGetAllEventsQuery();
  const { data: leagueData } = useGetAllLeaguesQuery();
  return (
    <CreateModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      header={'Create Team'}
      initialValues={{
        name: '',
        leagueId: '',
        eventId: '',
        drawId: '',
      }}
      inputs={[
        {
          htmlFor: 'name',
          label: 'Name',
          type: 'text',
        },
        {
          htmlFor: 'leagueId',
          label: 'League',
          type: ({ formik }) => (
            <Select placeholder="Select a League">
              {leagueData ? (
                leagueData.map((league) => (
                  <option key={league.id} value={league.id}>
                    {league.name}
                  </option>
                ))
              ) : (
                <option>Loading...</option>
              )}
            </Select>
          ),
        },
        {
          htmlFor: 'eventId',
          label: 'Event',
          type: ({ formik }) => (
            <Select placeholder="Select an Event">
              {eventData ? (
                eventData.map((event) => (
                  <option key={event.id} value={event.id}>
                    {event.name}
                  </option>
                ))
              ) : (
                <option>Loading...</option>
              )}
            </Select>
          ),
        },
      ]}
      validationSchema={yup.object().shape({
        name: yup.string().required('Name is required'),
        leagueId: yup.string().required('League is required'),
        eventId: yup.string().required('Event is required'),
      })}
      onSubmit={(values) => {
        console.log(values);
      }}
    />
  );
};

export default CreateTeamModal;
