import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { Cell } from 'react-table';
import { useUpdatePlayerStatsMutation } from '../../../app/api';
import { PlayerStats } from '../../../types';

interface OverrideScoreFieldInputProps {
  cell: Cell<PlayerStats>;
  triggerRefetch: () => void;
}

export const OverrideScoreFieldInput: React.FC<
  OverrideScoreFieldInputProps
> = ({ cell, triggerRefetch }) => {
  const [updatePlayerStats] = useUpdatePlayerStatsMutation();
  return (
    <NumberInput
      value={(cell.row.original as any)[cell.column.id]}
      min={0}
      onChange={(value) => {
        if (value === '' || parseInt(value) <= 0) {
          return;
        }
        updatePlayerStats({
          id: cell.row.original.id,
          stats: {
            onePoints: cell.row.original.onePoints,
            twoPoints: cell.row.original.twoPoints,
            threePoints: cell.row.original.threePoints,
            rebounds: cell.row.original.rebounds,
            blocks: cell.row.original.blocks,
            assists: cell.row.original.assists,
            steals: cell.row.original.steals,
            fouls: cell.row.original.fouls,
            foulsDrawn: cell.row.original.foulsDrawn,
            [cell.column.id]: parseInt(value),
          },
        }).then(() => {
          triggerRefetch();
        });
      }}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};
