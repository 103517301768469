import { Input, InputProps } from '@chakra-ui/react';

interface TimeInputProps extends InputProps {
  date?: Date;
  onTimeChange: (value: Date) => void;
}

export const TimeInput: React.FC<TimeInputProps> = ({
  children,
  date,
  onTimeChange,
  ...rest
}) => {
  const serializeDate = (date: Date) => {
    return `${('0' + date.getHours()).slice(-2)}:${(
      '0' + date.getMinutes()
    ).slice(-2)}`;
  };

  const deserializeValue = (hourMin: string) => {
    const currentDate = new Date(date || new Date());
    return new Date(
      `${currentDate.toLocaleDateString()} ${
        hourMin === '' ? '00:00' : hourMin
      }:00.000`
    );
  };

  return (
    <Input
      {...rest}
      type="time"
      value={serializeDate(date || new Date())}
      onChange={(e) => onTimeChange(deserializeValue(e.target.value))}
    >
      {children}
    </Input>
  );
};
