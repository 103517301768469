import {
  ProgramItem,
  useProgram,
} from 'planby';
import React, { memo, useState } from 'react';
import { GameView } from 'types';
import { GameBox } from './GameBox';

interface GameTimelineItemProps {
  game: GameView;
  program: ProgramItem;
  onGameSelect?: () => void;
  editing?: boolean;
}

export const GameTimelineItem: React.FC<GameTimelineItemProps> = memo(({
  game,
  program,
  onGameSelect,
  editing,
}) => {
  const [hover, setHover] = useState(false);
  const { styles } = useProgram(program);
  
  return (
        <GameBox
          pos="absolute"
          width={styles.width} style={{ ...styles.position }}
          alignItems='center'
          game={game}
          hover={hover}
          editing={editing}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onGameSelect}
          />
  );
}, (prev, next) => prev.game.scheduledAt === next.game.scheduledAt && prev.game.courtId === next.game.courtId && prev.editing === next.editing);
