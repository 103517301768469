import {
  AddIcon,
  CheckCircleIcon,
  CloseIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@chakra-ui/icons';
import {
  Button,
  Collapse,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { FieldArray, Formik } from 'formik';
import {
  useGetCourtsForEventQuery,
  useUpsertCourtsMutation,
} from '../../../app/api';
import Card from '../../../components/Card/Card';
import { Court } from '../../../types';

interface CreateCourtSectionProps {
  eventId: number;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export const CreateCourtSection: React.FC<CreateCourtSectionProps> = ({
  isOpen,
  onClose,
  onOpen,
  eventId,
}) => {
  const { data: courts } = useGetCourtsForEventQuery(eventId);
  const [upsertCourtsMutation, { data: upsertCourtsResult, error }] =
    useUpsertCourtsMutation();
  const saveButtonColor = useColorModeValue('orange', 'blue');
  return (
    <Card>
      <HStack w="100%">
        <Heading as="h3" size="md">
          Create Courts
        </Heading>
        {/* <Button>Batch Create</Button> */}
        {upsertCourtsResult && <Icon as={CheckCircleIcon} color="green" />}
        {isOpen ? (
          <TriangleUpIcon onClick={onClose} />
        ) : (
          <TriangleDownIcon onClick={onOpen} />
        )}
      </HStack>
      <Collapse in={isOpen}>
        <Formik
          initialValues={{ courts: upsertCourtsResult || courts || [] }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            upsertCourtsMutation({ eventId, courts: values.courts }).then(
              () => {
                setSubmitting(false);
                onClose();
              }
            );
          }}
          enableReinitialize={true}
        >
          {({ values, isSubmitting, submitForm, setValues }) => (
            <FieldArray name="courts">
              {({ push, remove, replace }) => (
                <VStack>
                  {values.courts.map((court: Partial<Court>, index) => (
                    <HStack key={`court-${index}`} alignItems="flex-end">
                      <FormControl>
                        <FormLabel htmlFor={`court-${index}`}>
                          Court Name
                        </FormLabel>
                        <Input
                          id={`court-${index}`}
                          onChange={(e) =>
                            replace(index, { ...court, name: e.target.value })
                          }
                          value={court.name}
                          placeholder="New Court"
                        />
                      </FormControl>
                      <IconButton
                        icon={<CloseIcon />}
                        onClick={() => remove(index)}
                        aria-label={'remove-court'}
                      />
                    </HStack>
                  ))}
                  <HStack>
                    <Button
                      isDisabled={isSubmitting}
                      onClick={() => push({ name: '', eventId })}
                    >
                      Add <AddIcon />
                    </Button>
                    <Button
                      onClick={submitForm}
                      isLoading={isSubmitting}
                      colorScheme={saveButtonColor}
                    >
                      Save
                    </Button>
                  </HStack>
                </VStack>
              )}
            </FieldArray>
          )}
        </Formik>
      </Collapse>
    </Card>
  );
};
