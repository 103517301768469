import {Input, InputProps} from '@chakra-ui/react';
import React from 'react';

interface DateInputProps extends InputProps {
  date?: Date;
  onDateChange?: (value: Date) => void;
  isDisabled?: boolean
}

export const DateTimeInput: React.FC<DateInputProps> =
  ({
     children,
     date,
     onDateChange,
     isDisabled,
     ...rest
   }) => {

    const serializeDate = (date: Date) => {
      date = new Date(date);
      return new Date(date.getTime() + date.getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 16)
    };

    return (
      <Input
        {...rest}
        type="datetime-local"
        value={serializeDate(date || new Date())}
        onChange={(e) => onDateChange ? onDateChange(new Date(e.target.value)) : null}
        disabled={isDisabled || false}
      >
        {children}
      </Input>
    );
  };
