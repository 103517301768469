import { DownloadIcon } from '@chakra-ui/icons';
import {
  Button,
  Heading,
  HStack,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useSaveScheduleMutation } from 'app/api';
import { useAppSelector } from 'app/store';
import Card from 'components/Card/Card';
import { GameTimeline } from 'components/GameTimeline/GameTimeline';
import { useEventContext } from 'contexts/EventContext';
import GameViewModal from 'pages/games/GameViewModal';
import React, { useEffect, useState } from 'react';
import { GameView, Schedule } from 'types';
import { useExportScheduleMutation } from '../../../../app/api';
import { SimulateGamesModal } from '../SimulateGamesModal';

interface SportEventSchedulePanelProps {
  editing: boolean;
  setEditing: (editing: boolean) => void;
  schedule?: Schedule;
  refetchSchedule: () => void;
}

export const SportEventSchedulePanel: React.FC<
  SportEventSchedulePanelProps
> = ({ editing, setEditing, schedule, refetchSchedule }) => {
  const csvDownloadRef = React.useRef<HTMLAnchorElement>(null);
  const [selectedGame, setSelectedGame] = useState<GameView | undefined>(
    undefined
  );
  const [editingSchedule, setEditingSchedule] = useState<Schedule | undefined>(
    schedule
  );
  const [exportObjectUri, setExportObjectUri] = useState<string | undefined>(
    undefined
  );
  const [valid, setValid] = useState<boolean | undefined>(undefined);
  const userStore = useAppSelector((store) => store.user);
  const [saveScheduleMutation] = useSaveScheduleMutation();
  const [exportSchedule] = useExportScheduleMutation();
  const { currentEvent, canPerformEventAction } = useEventContext();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleSaveEditButtonClick = () => {
    if (editing && editingSchedule && currentEvent) {
      saveScheduleMutation({
        schedule: editingSchedule,
        eventId: currentEvent.id,
      }).then(refetchSchedule);
    }
    setEditing(!editing);
  };

  useEffect(() => {
    if (schedule) {
      setEditingSchedule(schedule);
    }
  }, [schedule, setEditingSchedule]);

  return (
    <Card overflow="auto">
      <HStack justify="space-between">
        <Heading>Court Schedule {editing && '(Editing)'}</Heading>
        <HStack>
          {canPerformEventAction('EVENT_ADMIN') && (
            <Button onClick={onOpen}>Simulate</Button>
          )}
          {canPerformEventAction('EVENT_ADMIN') && (
            <Button
              isDisabled={editing && !valid}
              aria-label={editing ? 'finish editing' : 'edit game schedule'}
              onClick={handleSaveEditButtonClick}
            >
              {editing ? 'Save ' : 'Edit '}
            </Button>
          )}
          <IconButton
            aria-label="export schedule"
            icon={<DownloadIcon />}
            onClick={async (e) => {
              if (currentEvent) {
                setExportObjectUri(
                  await exportSchedule(currentEvent.id).unwrap()
                );
                csvDownloadRef.current?.click();
              }
            }}
          />
          <a
            ref={csvDownloadRef}
            hidden
            href={exportObjectUri}
            download="schedule.csv"
          ></a>
        </HStack>
      </HStack>
      {schedule ? (
        <GameTimeline
          onScheduleChange={setEditingSchedule}
          editing={editing}
          schedule={schedule}
          editingSchedule={editingSchedule}
          onGameSelect={setSelectedGame}
          onValidChange={setValid}
        />
      ) : (
        <Text>No schedule found</Text>
      )}
      {selectedGame && (
        <GameViewModal
          game={selectedGame}
          onClose={() => setSelectedGame(undefined)}
          isOpen={!!selectedGame}
        />
      )}
      <SimulateGamesModal isOpen={isOpen} onClose={onClose} />
    </Card>
  );
};
