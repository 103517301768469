import { Box } from '@chakra-ui/react';
import { BasketballLoader } from './BasketballLoader';

interface BasketballLoaderOverlayProps {
  show: boolean;
}

export const BasketballLoaderOverlay: React.FC<
  BasketballLoaderOverlayProps
> = ({ show, children }) => {
  return show ? (
    <Box
      borderRadius="xl"
      position="absolute"
      backgroundColor="blackAlpha.600"
      zIndex={100}
      left={0}
      top={0}
      w="full"
      h="full"
    >
      <BasketballLoader />
    </Box>
  ) : (
    <>{children}</>
  );
};
