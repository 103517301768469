import CreateModal from '../../components/CreateModal';
import * as yup from 'yup';
import {
  useCreateBulkGamesForEventMutation,
  useCreateGameForEventMutation,
  useGetCourtsForEventQuery,
  useGetLeaguesForEventQuery,
  useGetTeamsForEventQuery
} from '../../app/api';
import React, {useEffect, useState} from 'react';
import {DateTimeInput} from '../../components/DateTimeInput';
import {Game, Team} from '../../types';
import {FormControl, FormLabel, Input, Switch} from '@chakra-ui/react';
import {FormikProps} from 'formik';
import {BulkCreateGamesRequest} from '../../types/dto';

interface CreateGameModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  afterSubmit: () => void;
  eventId: number;
}

const CreateGameModal: React.FC<CreateGameModalProps> = ({
                                                           isOpen,
                                                           onClose,
                                                           onOpen,
                                                           afterSubmit,
                                                           eventId,
                                                         }) => {
  const [createGame] = useCreateGameForEventMutation();
  const [createBulkGames] = useCreateBulkGamesForEventMutation();
  const {data: leagues} = useGetLeaguesForEventQuery(eventId);
  const {data: teams} = useGetTeamsForEventQuery(eventId);
  const {data: courts} = useGetCourtsForEventQuery(eventId);

  const [filteredTeams, setFilteredTeams] = useState<Team[]>([]);
  const [selectedLeagueId, setSelectedLeagueId] = useState<number>(-1);
  const [isBulkCreation, setIsBulkCreation] = useState(false);


  useEffect(() => {
    if (selectedLeagueId === -1) {
      setFilteredTeams([]);
    } else if (teams && teams.length > 0) {
      const newFilteredTeams: Team[] = teams.filter(
        (team) => team.leagueId === selectedLeagueId
      )
      setFilteredTeams(newFilteredTeams);
    }
  }, [teams, selectedLeagueId]);


  async function handleSingleGameCreateSubmit(values: object) {
    const vals = values as CreateGameFormValues;
    const game: Partial<Game> = {
      courtId: parseInt(vals.courtId),
      awayTeamId: parseInt(vals.awayTeamId),
      homeTeamId: parseInt(vals.homeTeamId),
      eventId: eventId,
      leagueId: parseInt(vals.leagueId),
      scheduledAt: vals.startTime,
      endTime: vals.endTime,
      startTime: vals.startTime,
      awayTeamScore: 0,
      homeTeamScore: 0,
      played: false,
    };
    await createGame({
      game: game,
    }).unwrap();
  }

  async function handleBulkCreateSubmit(values: object) {
    const vals = values as CreateGameFormValues;
    const bulkGamesData: BulkCreateGamesRequest = {
      timeBetweenGames: vals.breakBetween,
      courts: (courts ?? []).filter((court) => vals.courtIds.includes(court.id.toString())),
      requestType: vals.type,
      startTime: vals.startTime,
      leagueId: parseInt(vals.leagueId),
      gameDuration: vals.duration,
    }
    await createBulkGames({
      eventId: eventId,
      bulkGamesData: bulkGamesData
    }).unwrap();
  }

  const commonInputs = [
    {
      htmlFor: 'leagueId',
      label: 'League',
      type: ({formik}: { formik: FormikProps<any> }) => (
        <Input
          as="select"
          id={formik.getFieldProps('leagueId').name}
          {...formik.getFieldProps('leagueId')}
          onChange={(e) => {
            setSelectedLeagueId(parseInt(e.target.value));
            formik.handleChange(e);
          }}
        >
          <option value="-1" disabled>
            Select League
          </option>
          {leagues &&
            leagues.map((league) => (
              <option key={league.id} value={league.id.toString()}>
                {league.name}
              </option>
            ))}
        </Input>
      ),
    },
    {
      htmlFor: 'startTime',
      label: 'Start Time',
      type: ({formik}: { formik: FormikProps<any> }) => (
        <DateTimeInput
          date={formik.values.startTime}
          onDateChange={(newDate) => {
            if (!isNaN(newDate.getTime())) {
              formik.setFieldValue('startTime', newDate);
            }
          }}
        />
      ),
    },
  ];


  const singleGameInputs = [
    ...commonInputs,
    {
      htmlFor: 'homeTeamId',
      label: 'Home Team',
      type: ({formik}: { formik: FormikProps<any> }) => (
        <Input
          as="select"
          id={formik.getFieldProps('homeTeamId').name}
          {...formik.getFieldProps('homeTeamId')}
        >
          <option value="-1" disabled>
            Select Home Team
          </option>
          {filteredTeams.map((team) => (
            <option key={team.id} value={team.id.toString()}>
              {team.name}
            </option>
          ))}
        </Input>
      ),
    },
    {
      htmlFor: 'awayTeamId',
      label: 'Away Team',
      type: ({formik}: { formik: FormikProps<any> }) => (
        <Input
          as="select"
          id={formik.getFieldProps('awayTeamId').name}
          {...formik.getFieldProps('awayTeamId')}
        >
          <option value="-1" disabled>
            Select Away Team
          </option>
          {filteredTeams.map((team) => (
            <option key={team.id} value={team.id.toString()}>
              {team.name}
            </option>
          ))}
        </Input>
      ),
    },
    {
      htmlFor: 'courtId',
      label: 'Court',
      type: ({formik}: { formik: FormikProps<any> }) => (
        <Input
          as="select"
          id={formik.getFieldProps('courtId').name}
          {...formik.getFieldProps('courtId')}
        >
          <option value="-1" disabled>
            Select Court
          </option>
          {courts &&
            courts.map((court) => (
              <option key={court.id} value={court.id.toString()}>
                {court.name}
              </option>
            ))}
        </Input>
      ),
    },
    {
      htmlFor: 'endTime',
      label: 'End Time',
      type: ({formik}: { formik: FormikProps<any> }) => (
        <DateTimeInput
          date={formik.values.endTime}
          onDateChange={(newDate) => {
            if (!isNaN(newDate.getTime())) {
              formik.setFieldValue('endTime', newDate);
            }
          }}
        />
      ),
    }]

  const bulkGameInputs = [
    ...commonInputs,
    {
      htmlFor: 'type',
      label: 'Type',
      type: ({formik}: { formik: FormikProps<any> }) => (
        <Input
          as="select"
          id={formik.getFieldProps('type').name}
          {...formik.getFieldProps('type')}
        >
          <option value="ranking">Create Games Based on Ranking</option>
          <option value="random">Create Games Randomly</option>
        </Input>
      ),
    },
    {
      htmlFor: 'courtIds',
      label: 'Courts',
      type: ({formik}: { formik: FormikProps<any> }) => (
        <Input
          as="select"
          id={formik.getFieldProps('courtIds').name}
          {...formik.getFieldProps('courtIds')}
          multiple
          h="auto"
        >
          <option value="-1" disabled>
            Select Court
          </option>
          {courts &&
            courts.map((court) => (
              <option key={court.id} value={court.id.toString()}>
                {court.name}
              </option>
            ))}
        </Input>
      ),
    },
    {
      htmlFor: 'duration',
      label: 'Duration (minutes)',
      type: ({formik}: { formik: FormikProps<any> }) => (
        <Input
          id={formik.getFieldProps('duration').name}
          {...formik.getFieldProps('duration')}
          type="number"
        />
      ),
    },
    {
      htmlFor: 'breakBetween',
      label: 'Break Between Games (minutes)',
      type: ({formik}: { formik: FormikProps<any> }) => (
        <Input
          id={formik.getFieldProps('breakBetween').name}
          {...formik.getFieldProps('breakBetween')}
          type="number"
        />
      ),
    },
  ];

  const commonValidationSchema = {
    leagueId: yup.string().required('League is required'),
    startTime: yup
      .date()
      .required('Start Time is required')
  };

  const singleGameValidationSchema = yup.object().shape({
    ...commonValidationSchema,
    startTime: yup
      .date()
      .required('Start Time is required')
      .test(
        'is-before-end-time',
        'Start Time must be before End Time',
        function (value) {
          const {endTime} = this.parent;
          return !endTime || value! < endTime;
        }
      ),
    endTime: yup
      .date()
      .required('End Time is required')
      .test(
        'is-after-start-time',
        'End Time must be after Start Time',
        function (value) {
          const {startTime} = this.parent;
          return !startTime || value! > startTime;
        }
      ),
    homeTeamId: yup
      .string()
      .required('Home Team is required')
      .notOneOf(
        [yup.ref('awayTeamId')],
        'Home Team and Away Team must be different'
      )
      .test(
        'same-league-as-away',
        'Home Team and Away Team must belong to the same league',
        function (value) {
          const {awayTeamId} = this.parent;
          const homeTeam = teams?.find(
            (team) => team.id === parseInt(value!)
          );
          const awayTeam = teams?.find(
            (team) => team.id === parseInt(awayTeamId as string)
          );
          return homeTeam?.leagueId === awayTeam?.leagueId;
        }
      ),
    awayTeamId: yup
      .string()
      .required('Away Team is required')
      .notOneOf(
        [yup.ref('homeTeamId')],
        'Home Team and Away Team must be different'
      ),
    courtId: yup.string().required('Court is required'),
  });

  const bulkGameValidationSchema = yup.object().shape({
    ...commonValidationSchema,
    duration: yup
      .number()
      .min(1, 'Duration must be at least 1 minute')
      .required('Duration is required'),
    courtIds: yup
      .array()
      .of(yup.string())
      .min(1, 'Please select at least one court'),
    breakBetween: yup
      .number()
      .min(1, 'Break Between must be at least 1 minute')
      .required('Break Between is required'),
  });

  const commonInitialValues = {
    leagueId: '-1',
    startTime: new Date(),
    type: 'ranking'
  }

  const bulkGameInitialValues = {
    ...commonInitialValues,
    duration: 20,
    courtIds: [],
    breakBetween: 20
  }

  const singleGameInitialValues = {
    ...commonInitialValues,
    homeTeamId: '-1',
    awayTeamId: '-1',
    courtId: '-1',
    endTime: new Date(),
  }


  return (
    <CreateModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      header={isBulkCreation ? 'Create Bulk Games' : 'Create Game'}
      initialValues={isBulkCreation ? bulkGameInitialValues : singleGameInitialValues}
      inputs={[
        {
          htmlFor: 'bulkCreation',
          label: '',
          type: () => (
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="bulk-creation-switch" mb="0">
                Create Bulk Games
              </FormLabel>
              <Switch
                id="bulk-creation-switch"
                onChange={(e) => setIsBulkCreation(e.target.checked)}
                isChecked={isBulkCreation}
              />
            </FormControl>
          ),
        },
        ...(isBulkCreation ? bulkGameInputs : singleGameInputs),
      ]}
      validationSchema={isBulkCreation ? bulkGameValidationSchema : singleGameValidationSchema}
      onSubmit={async (values, formik) => {
        if (isBulkCreation) {
          await handleBulkCreateSubmit(values);
        } else {
          await handleSingleGameCreateSubmit(values);
        }
        formik.setSubmitting(false);
        onClose();
        afterSubmit();
      }}
    />
  );
};

interface CreateGameFormValues {
  homeTeamId: string;
  awayTeamId: string;
  courtId: string;
  leagueId: string;
  startTime: Date;
  endTime: Date;
  duration: number;
  breakBetween: number;
  type: 'ranking' | 'random';
  courtIds: string[];
}

export default CreateGameModal;
