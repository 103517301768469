import CreateModal from '../../components/CreateModal';
import * as yup from 'yup';
import { Select } from '@chakra-ui/react';
import { useGetAllEventsQuery } from '../../app/api';

interface CreateCourtModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const CreateCourtModal: React.FC<CreateCourtModalProps> = ({
  isOpen,
  onClose,
  onOpen,
}) => {
  const { data } = useGetAllEventsQuery();
  return (
    <CreateModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      header={'Create Court'}
      initialValues={{
        name: '',
        event: '',
      }}
      inputs={[
        {
          htmlFor: 'name',
          label: 'Name',
          type: 'text',
        },
        {
          htmlFor: 'event',
          label: 'Event',
          type: ({ formik }) => (
            <Select placeholder="Select an Event">
              {data ? (
                data.map((event) => (
                  <option key={event.id} value={event.id}>
                    {event.name}
                  </option>
                ))
              ) : (
                <option>Loading...</option>
              )}
            </Select>
          ),
        },
      ]}
      validationSchema={yup.object().shape({
        name: yup.string().required('Name is required'),
        event: yup.number().required('Event is required'),
      })}
      onSubmit={(values) => {
        console.log(values);
      }}
    />
  );
};

export default CreateCourtModal;
