import { Heading, useColorModeValue } from "@chakra-ui/react";
import { ResponsivePie } from "@nivo/pie";
import Card from "components/Card/Card";
import { Schedule } from "types";

interface SportEventGamesPlayedPanelProps {
    games?: Schedule
    countGames: (played: boolean) => number
}

export const SportEventGamesPlayedPanel: React.FC<SportEventGamesPlayedPanelProps> = ({ games, countGames }) => {
    return (
        <Card h="250px" minW="350px">
        <Heading>Games Played</Heading>
        <ResponsivePie
          data={
            games
              ? ['Played', 'Not Played'].map((category) => ({
                  id: category,
                  label: category,
                  value: countGames(category === 'Played'),
                }))
              : []
          }
          theme={{
            textColor: useColorModeValue('#000', '#fff'),
          }}
          colors={(datum) => (datum.id === 'Played' ? '#38A169' : '#E53E3E')}
          innerRadius={0.5}
        />
      </Card>
    );
}