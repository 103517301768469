import { TriangleUpIcon, TriangleDownIcon, AddIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Collapse,
  Heading,
  HStack,
  IconButton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetLeaguesForEventQuery, useGetTeamsForEventQuery } from 'app/api';
import Card from 'components/Card/Card';
import { useEventContext } from 'contexts/EventContext';
import { useEffect, useState } from 'react';
import { LeagueEvent } from 'types';
import { AddTeamsModal } from './AddTeamsModal';

interface ManageTeamsProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export const ManageTeams: React.FC<ManageTeamsProps> = ({
  isOpen,
  onClose,
  onOpen,
}) => {
  const [selectedLeague, setSelectedLeague] = useState<
    LeagueEvent | undefined
  >();
  const { currentEvent } = useEventContext();
  const { data: teams, refetch } = useGetTeamsForEventQuery(
    currentEvent?.id ?? skipToken
  );
  const { data: leagues } = useGetLeaguesForEventQuery(
    currentEvent?.id ?? skipToken
  );
  const {
    isOpen: isCreateTeamOpen,
    onOpen: onCreateTeamOpen,
    onClose: onCreateTeamClose,
  } = useDisclosure();

  const getTeamCount = (leagueId: number) => {
    return teams?.filter((t) => t.leagueId === leagueId).length || 0;
  };

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  return (
    <Card>
      <HStack>
        <Heading as="h3" size="md">
          Manage Teams
        </Heading>
        {isOpen ? (
          <TriangleUpIcon onClick={onClose} />
        ) : (
          <TriangleDownIcon onClick={onOpen} />
        )}
      </HStack>
      <Collapse in={isOpen}>
        <VStack>
          <Alert status="info">
            <AlertIcon />
            <AlertTitle>
              Number of teams for each league must be even.
            </AlertTitle>
            <AlertDescription>Create new Teams here.</AlertDescription>
          </Alert>
          {leagues?.map((league) => (
            <VStack key={league.id}>
              <HStack>
                <Heading as="h4" size="sm">
                  {league.name}
                </Heading>
                <IconButton
                  variant={'ghost'}
                  aria-label="Add Team"
                  onClick={() => {
                    setSelectedLeague(league);
                  }}
                  icon={<AddIcon />}
                  size="xs"
                />
              </HStack>
              <Text
                color={getTeamCount(league.id) % 2 !== 0 ? 'red' : undefined}
              >
                Count: {getTeamCount(league.id)}
              </Text>
            </VStack>
          ))}
          {selectedLeague && (
            <AddTeamsModal
              isOpen={!!selectedLeague}
              onClose={() => {
                setSelectedLeague(undefined);
                onCreateTeamClose();
              }}
              league={selectedLeague}
              refetchTeams={refetch}
            />
          )}
        </VStack>
      </Collapse>
    </Card>
  );
};
