import { Flex, Heading, HStack, useColorModeValue, VStack } from "@chakra-ui/react";
import { useDroppable } from "@dnd-kit/core";
import { differenceInMinutes } from "date-fns";
import { GameView } from "types/dto";
import { GameBox } from "./GameBox";

interface UnscheduledGameListProps {
    games: GameView[];
    hourWidth: number;
    editing: boolean;
}

export const UnscheduledGameList: React.FC<UnscheduledGameListProps> = ({ games, hourWidth, editing }) => {

    const {setNodeRef} = useDroppable({
        id: 'unscheduled-games'
    })

    return (
        <Flex p={3} flexDir="column" w="full" minH="100px" borderRadius="xl" backgroundColor={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}>
            <Heading color={useColorModeValue("blackAlpha.500", "whiteAlpha.500")} size="sm">Unscheduled Games</Heading>
            <HStack ref={setNodeRef} w="full" minH="90px    " overflow="auto">
                {games.map(g =>
                    <GameBox key={g.id} hover={false} editing={editing} game={g} width={hourWidth * differenceInMinutes(new Date(g.endTime), new Date(g.startTime)) / 60 + "px"} flexShrink={0} h="80px" />
                )}
            </HStack>
        </Flex>
    );
}