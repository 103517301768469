import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useRequestPasswordResetMutation } from 'app/api';
import { useState } from 'react';

interface ForgotPasswordProps {}

export const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const toast = useToast();
  const [resetPassword, { isLoading }] = useRequestPasswordResetMutation();
  const bgcolor = useColorModeValue('gray.50', 'gray.800');
  const buttonColor = useColorModeValue('orange.500', 'blue.500');
  const [email, setEmail] = useState('');
  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={bgcolor}>
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Forgot your password?
        </Heading>
        <Text fontSize={{ base: 'sm', sm: 'md' }}>
          You&apos;ll get an email with a reset link
        </Text>
        <FormControl id="email">
          <Input
            value={email}
            placeholder="your-email@example.com"
            _placeholder={{ color: 'gray.500' }}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <Stack spacing={6}>
          <Button
            bg={buttonColor}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            onClick={async () => {
              await resetPassword(email);
              toast({
                title: 'Success',
                status: 'success',
                description:
                  'If your email is valid, you will recieve a request to reset your password',
                duration: 5000,
              });
            }}
          >
            Request Reset
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
};
