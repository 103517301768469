import { CheckCircleIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  Flex,
  HStack,
  IconButton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useGetCourtsForEventQuery } from 'app/api';
import { Bracket, Seed, SeedItem, SeedTime } from 'react-brackets';
import { useEventContext } from '../../contexts/EventContext';
import { Team } from '../../types';
import { Round } from '../../types/dto';
import { PlayoffGameModal } from '../PlayoffGameModal';

export interface PlayoffBracketProps {
  schedule: Round[];
  teams: Team[];
}

export const SingleEliminationPlayoffBracket: React.FC<PlayoffBracketProps> = ({
  schedule,
  teams,
}) => {
  return (
    <Bracket
      rounds={schedule.map((s) => ({
        ...s,
        seeds: s.games.map((g) => {
          return {
            ...g,
            id: g.id,
            teams: [
              teams.find((t) => t.id === g.homeTeamId)!,
              teams.find((t) => t.id === g.awayTeamId)!,
            ],
          };
        }),
      }))}
      swipeableProps={{ enableMouseEvents: true }}
      renderSeedComponent={({ seed, breakpoint }) => (
        <SeedComponent seed={seed} breakpoint={breakpoint} />
      )}
    />
  );
};

const SeedComponent: React.FC<{ seed: any; breakpoint: number }> = ({
  seed,
  breakpoint,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { currentEvent } = useEventContext();
  const { data: courts } = useGetCourtsForEventQuery(
    currentEvent ? currentEvent.id : skipToken
  );
  const home = seed.teams?.[0];
  const away = seed.teams?.[1];
  const homeTeamWon =
    seed.homeTeamId && seed.homeTeamScore > seed.awayTeamScore;
  const awayTeamWon =
    seed.awayTeamId && seed.awayTeamScore > seed.homeTeamScore;
  return (
    <>
      <Seed mobileBreakpoint={breakpoint}>
        <SeedItem>
          <HStack justify="space-between" px="2" py="1">
            <VStack>
              <Flex w="100%" align="left">
                {home ? (
                  <Text
                    textShadow={
                      homeTeamWon ? '1px 0px 10px #ffffff' : undefined
                    }
                    fontWeight={homeTeamWon ? 'bold' : 'normal'}
                  >
                    {home.rank ? home.rank + ') ' : ''} {home.name}
                  </Text>
                ) : (
                  '----------'
                )}
              </Flex>
              <Flex w="100%" align="left">
                {away ? (
                  <Text
                    textShadow={
                      awayTeamWon ? '1px 0px 10px #ffffff' : undefined
                    }
                    fontWeight={awayTeamWon ? 'bold' : 'normal'}
                  >
                    {away.rank ? away.rank + ') ' : ''} {away.name}
                  </Text>
                ) : (
                  '----------'
                )}
              </Flex>
            </VStack>
            <IconButton
              isDisabled={seed.played}
              onClick={onOpen}
              variant="ghost"
              icon={seed.played ? <CheckCircleIcon /> : <SettingsIcon />}
              aria-label="playoff-game-settings"
            />
          </HStack>
        </SeedItem>
        <SeedTime mobileBreakpoint={breakpoint}>
          {new Date(seed.startTime).toLocaleTimeString()} |{' '}
          {courts?.find((c) => c.id === seed.courtId)?.name ||
            'Court: ' + seed.courtId}
        </SeedTime>
      </Seed>
      {currentEvent && (
        <PlayoffGameModal
          gameId={seed.id}
          isOpen={isOpen}
          onClose={onClose}
          eventId={currentEvent.id}
        />
      )}
    </>
  );
};
