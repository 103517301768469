import { skipToken } from '@reduxjs/toolkit/query/react';
import { useAppSelector } from 'app/store';
import { BasketballLoader } from 'components/BasketballLoader/BasketballLoader';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { EventRoleType } from 'types/dto';
import { useGetEventByIdQuery } from '../app/api';
import { SportEventView } from '../types';
import { canPerformAction } from './AuthContext/AuthContext';

interface EventContextValue {
  currentEvent: SportEventView | null;
  setCurrentEvent: (event: SportEventView | null, navigate: boolean) => void;
  canPerformEventAction: (requiredRole: EventRoleType) => boolean;
}

const EventContext = createContext<EventContextValue>({
  currentEvent: null,
  setCurrentEvent: (event: SportEventView | null, navigate: boolean) => {},
  canPerformEventAction: (requiredRole: EventRoleType) => false,
});

export const useEventContext = () => useContext(EventContext);

export const EventContextProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [currentEvent, setCurrentEvent] = useState<SportEventView | null>(null);
  const user = useAppSelector((state) => state.user);

  const canPerformEventAction = useCallback(
    (requiredRole: EventRoleType) => {
      return canPerformAction(
        user.organizationUser?.eventRoles?.find(
          (role) => role.eventId === currentEvent?.id
        )?.role,
        requiredRole
      );
    },
    [user.organizationUser?.eventRoles, currentEvent?.id]
  );

  const updateCurrentEvent = (
    event: SportEventView | null,
    shouldNavigate: boolean
  ) => {
    setCurrentEvent(event);
    if (event && shouldNavigate) {
      navigate(`/events/${event?.id}`);
    }
  };

  return (
    <EventContext.Provider
      value={{
        currentEvent,
        setCurrentEvent: updateCurrentEvent,
        canPerformEventAction,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export function RequireEvent() {
  const { eventId } = useParams();
  const { currentEvent, setCurrentEvent } = useEventContext();
  const location = useLocation();
  const { data, isLoading, error, isSuccess } = useGetEventByIdQuery(
    !currentEvent && eventId ? parseInt(eventId) : skipToken
  );

  useEffect(() => {
    if (!currentEvent && data) {
      setCurrentEvent(data, false);
    }
  }, [currentEvent, data, setCurrentEvent]);

  if (isLoading || (data && !currentEvent)) {
    return <BasketballLoader />;
  }

  return currentEvent && !error ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
}
