import { extendTheme, theme } from '@chakra-ui/react';

export const customTheme = extendTheme(
  {
    colors: {
      white: '#ffffff',
      blue: {
        50: '#74B4FE',
        100: '#60AAFE',
        200: '#3794FE',
        300: '#0F7EFE',
        400: '#016AE3',
        500: '#0157BA',
        600: '#013D82',
        700: '#00234A',
        800: '#000913',
        900: '#000000',
      },
      orange: {
        50: '#FDE4DB',
        100: '#FCD5C8',
        200: '#F9B9A1',
        300: '#F79C7B',
        400: '#F47F54',
        500: '#F2622E',
        600: '#DA440E',
        700: '#A6330A',
        800: '#712307',
        900: '#3C1304',
      },
      gray: {
        50: '#EEEEEE',
        100: '#CCCCCC',
        200: '#9CA6AF',
        300: '#85929C',
        400: '#6F7E8A',
        500: '#5D6973',
        600: '#444D54',
        700: '#2B3035',
        800: '#121416',
        900: '#000000',
      },
      brand: {
        900: '#011826',
        800: '#f2622e',
        700: '#5d6973',
        600: '#032340',
      },
    },
    styles: {
      global: {
        a: {
          p: 1,
        },
      },
    },
  },
  theme
);
