import {FaDownload, FaEdit, FaPlus} from 'react-icons/fa';
import {HStack, IconButton, Input, InputGroup, InputLeftElement, StackProps, Tooltip,} from '@chakra-ui/react';
import {SearchIcon} from '@chakra-ui/icons';
import {MdRefresh} from 'react-icons/all';

interface TableControlsProps extends StackProps {
  addAction?: () => void;
  editAction?: () => void;
  downloadAction?: () => void;
  search?: string;
  setSearch?: (search: string) => void;
  refreshAction?: () => void;
}

export const TableControls: React.FC<TableControlsProps> = ({
  addAction,
  editAction,
  downloadAction,
  search,
  setSearch,
  refreshAction,
}) => {
  return (
    <HStack marginY="2">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.400" boxSize={4} />
        </InputLeftElement>
        <Input
          onChange={setSearch ? (e) => setSearch(e.target.value) : undefined}
          placeholder="Search"
          value={search}
        ></Input>
      </InputGroup>
      {addAction && <Tooltip label="Create New">
        <IconButton
          variant="ghost"
          aria-label="create-new"
          icon={<FaPlus />}
          onClick={addAction}
        />
      </Tooltip>}
      {editAction && <Tooltip label="Edit">
        <IconButton
          variant="ghost"
          aria-label="select-multiple"
          icon={<FaEdit />}
          onClick={editAction}
        />
      </Tooltip>}
      {downloadAction && <Tooltip label="Download">
        <IconButton
          variant="ghost"
          aria-label="download"
          icon={<FaDownload/>}
          onClick={downloadAction}
        />
      </Tooltip>}
      {refreshAction && <Tooltip label="Refresh">
        <IconButton
        variant="ghost"
        aria-label="refresh"
        icon={<MdRefresh />}
        onClick={refreshAction} />
      </Tooltip>}
    </HStack>
  );
};
