import { SettingsIcon } from '@chakra-ui/icons';
import { HStack, Heading, IconButton, VStack } from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  assumeOrgUser,
  useAssumeOrgUserMutation,
  useGetOrganizationsByUserIdQuery,
} from 'app/api';
import { useAppSelector } from 'app/store';
import Card from 'components/Card/Card';
import Page from 'components/Page/Page';
import { YCTable } from 'components/YCTable/YCTable';
import { useAuth } from 'contexts/AuthContext/AuthContext';
import { useOrganizationContext } from 'contexts/OrganizationContext';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell } from 'react-table';
import { OrganizationUser, OrganizationsByUser } from 'types/dto';

export const Organizations: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { currentOrg, setCurrentOrg } = useOrganizationContext();
  const userStore = useAppSelector((state) => state.user);
  const { data, isLoading, error } = useGetOrganizationsByUserIdQuery(
    userStore?.user ? userStore.user.id : skipToken
  );
  const [assume, { isLoading: assumeLoading }] = useAssumeOrgUserMutation();
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'organization.name',
      },
      {
        Header: 'Description',
        accessor: 'organization.description',
      },
      {
        Header: 'Owner',
        accessor: 'organization.owner.email',
      },
      {
        Header: 'Role',
        accessor: 'organizationRole',
      },
      {
        Header: 'Actions',
        accessor: 'organization.id',
        Cell: ({ value, row }: Cell<OrganizationsByUser>) => (
          <IconButton
            colorScheme="blue"
            aria-label="Manage"
            icon={<SettingsIcon />}
            onClick={() => {
              chooseOrg(row.original);
            }}
          />
        ),
        // button that links to organization management page
      },
    ],
    [navigate]
  );

  const chooseOrg = async (org: OrganizationsByUser) => {
    await assume(org.id);
  };

  useEffect(() => {
    if (userStore.organizationUser) {
      navigate(`/organizations/${userStore.organizationUser.organizationId}`);
    }
  }, [userStore.organizationUser]);

  useEffect(() => {
    if (data?.length == 1) {
      chooseOrg(data[0]);
    }
  }, [data]);

  return (
    <Page flexDir="row" display="flex">
      <Card>
        <Heading>Organizations</Heading>
        <YCTable columns={columns} data={data || []} />
      </Card>
    </Page>
  );
};
