import { Box } from "@chakra-ui/react";
import { useDroppable } from "@dnd-kit/core";
import { ProgramItem, useProgram } from "planby";
import React from "react";

interface DroppableTimeSlotProps {
    program: ProgramItem;
}

export const DroppableTimeSlot: React.FC<DroppableTimeSlotProps> = React.memo(({ program }) => {
    const { styles, formatTime } = useProgram(program);
    const { setNodeRef } = useDroppable({
        id: program.program.data.id,
        data: {
            startTime: program.program.data.since,
            endTime: program.program.data.till
        }
    });

    return (
        <Box position="absolute" ref={setNodeRef} width={styles.width} style={{ ...styles.position }} />
    );
});