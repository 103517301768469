import { SkeletonText, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import React from 'react';

interface SkeletonStatsProps {
  isLoading: boolean;
  stat: string | number | undefined;
  label: string;
}

const SkeletonStats: React.FC<SkeletonStatsProps> = ({
  isLoading,
  stat,
  label,
}) => {
  return (
    <Stat>
      <StatLabel>{label}</StatLabel>
      <SkeletonText isLoaded={!isLoading}>
        <StatNumber>{stat}</StatNumber>
      </SkeletonText>
    </Stat>
  );
};

export default SkeletonStats;
