import { GameView, Schedule } from '../../types';
import { TimelineContextProvider } from './TimelineContext';
import { EditableGameEPG } from './EditableGameEPG';
import { differenceInMinutes } from 'date-fns';

interface GameTimelineProps {
  schedule: Schedule;
  editingSchedule?: Schedule;
  onScheduleChange?: (schedule: Schedule) => void;
  onGameSelect?: (game?: GameView) => void;
  onValidChange?: (valid: boolean) => void;
  editing?: boolean;
}

export const GameTimeline: React.FC<GameTimelineProps> = ({
  schedule,
  editingSchedule,
  onScheduleChange,
  onGameSelect,
  onValidChange,
  editing,
}) => {
  const exampleGame = schedule.days.flatMap((d) => d.games)[0];
  const gameDurationMins = exampleGame
    ? differenceInMinutes(
        new Date(exampleGame.endTime),
        new Date(exampleGame.startTime)
      )
    : 30;

  // JSX
  return (
    <TimelineContextProvider
      schedule={schedule}
      editingSchedule={editingSchedule}
      onEditingScheduleChange={onScheduleChange}
      onGameSelect={onGameSelect}
      onScheduleValidChange={onValidChange ? onValidChange : () => {}}
    >
      <EditableGameEPG
        editing={!!editing}
        gameDurationMins={gameDurationMins}
      />
    </TimelineContextProvider>
  );
};
