import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
  useColorModeValue,
  useToast,
  VStack
} from '@chakra-ui/react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  useDeleteSubEventMutation,
  useGetSubEventsForEventByIdQuery,
  useGetSubEventsForEventQuery,
  useUpdateSubEventMutation
} from '../../app/api';
import Page from '../../components/Page/Page';
import {useEventContext} from '../../contexts/EventContext';
import {DateTimeInput} from '../../components/DateTimeInput';
import {useEffect, useState} from 'react';
import {SubEvent} from '../../types/dto';
import {skipToken} from '@reduxjs/toolkit/dist/query/react';
import {useOrganizationContext} from '../../contexts/OrganizationContext';

export const SubEventViewPage: React.FC = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const {currentEvent} = useEventContext();
  const {currentOrg} = useOrganizationContext();

  const {data, refetch} = useGetSubEventsForEventByIdQuery({
    eventId: currentEvent?.id || -1,
    subEventId: id ? parseInt(id) : -1
  });


  const [deleteSubEvent, {isLoading: isUpdateLoading}] = useDeleteSubEventMutation();
  const [updateSubEvent, {isLoading: isDeleteLoading}] = useUpdateSubEventMutation();
  const {refetch: refetchAllSubEvents} = useGetSubEventsForEventQuery(
    currentEvent?.id ?? skipToken
  );

  const [title, setTitle] = useState<string>('');
  const [date, setDate] = useState<Date>(new Date());
  const [description, setDescription] = useState<string>('');


  const handleUpdate = (e: any) => {
    e.preventDefault();
    const updatedSubEvent: Partial<SubEvent> = {
      title: title,
      time: date,
      description: description
    }
    updateSubEvent({
      eventId: currentEvent?.id || -1,
      subEventId: id ? parseInt(id) : -1,
      subEvent: updatedSubEvent
    })
      .unwrap()
      .then(() => {
        toast({
          description: 'Update Successful',
          status: 'success',
          isClosable: true,
          duration: 5000
        });
        refetch();
      })
      .catch(e => {
        console.log(e)
        toast({
          description: 'Something Went Wrong',
          status: 'error',
          isClosable: true,
          duration: 5000
        });
      });
    return;
  }


  const handleDelete = (e: any) => {
    e.preventDefault();
    deleteSubEvent({
      eventId: currentEvent?.id || -1,
      subEventId: id ? parseInt(id) : -1
    })
      .unwrap()
      .then(() => {
        toast({
          description: 'Delete Successful',
          status: 'success',
          isClosable: true,
          duration: 5000
        });
        if (currentEvent != null && currentOrg != null) {
          navigate(`/organizations/${currentOrg.id}/events/${currentEvent.id}/subevents`);
          refetchAllSubEvents();
        } else {
          navigate('/');
        }
      })
      .catch(e => {
        console.log(e);
        toast({
          description: 'Something Went Wrong',
          status: 'error',
          isClosable: true,
          duration: 5000
        });
      });
  }

  useEffect(() => {
    if (data != null) {
      setTitle(data.title);
      setDate(data.time);
      setDescription(data.description)
    }
  }, [data]);

  return (
    <Page>
      <Box
        bg={useColorModeValue('white', 'gray.700')}
        borderRadius="lg"
        p={8}
        color={useColorModeValue('gray.700', 'whiteAlpha.900')}
        shadow="base">
        {data ? (
          <form>
            <VStack spacing={5}>
              <FormControl isRequired>
                <FormLabel>Title</FormLabel>

                <InputGroup>
                  <Input type="text" name="title" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Date</FormLabel>

                <InputGroup>
                  <DateTimeInput date={date} onDateChange={(newDate) => {
                    if (!isNaN(newDate.getTime())) {
                      setDate(newDate);
                    }
                  }}/>
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Description</FormLabel>

                <Textarea
                  name="description"
                  placeholder="Description"
                  rows={6}
                  resize="none"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>

              <ButtonGroup variant="outline" spacing="6">
                <Button colorScheme="blue" type="submit" onClick={handleUpdate} isDisabled={isDeleteLoading}
                        isLoading={isUpdateLoading}>Update</Button>
                <Button type="submit" onClick={handleDelete} isDisabled={isUpdateLoading} isLoading={isDeleteLoading}>Delete</Button>
              </ButtonGroup>
            </VStack>
          </form>
        ) : null}
      </Box>
    </Page>
  );
};


