import { Heading, useDisclosure } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import Page from '../../../components/Page/Page';
import { useEventContext } from '../../../contexts/EventContext';
import { CreateCourtSection } from './CreateCourtSection';
import { LeagueInfoSection } from './LeagueInfoSection';
import { ManageTeams } from './ManageTeams';
import { ScheduleCreationSection } from './ScheduleCreationSection';
import { SubEventCreationSection } from './SubEventCreationSection';

interface InitializeSportEventPageProps {}

export const InitializeSportEventPage: React.FC<
  InitializeSportEventPageProps
> = () => {
  const { currentEvent } = useEventContext();
  const {
    isOpen: isCourtsOpen,
    onOpen: onCourtsOpen,
    onClose: onCourtsClose,
  } = useDisclosure();
  const {
    isOpen: isLeagueInfoOpen,
    onOpen: onLeagueInfoOpen,
    onClose: onLeagueInfoClose,
  } = useDisclosure();
  const {
    isOpen: isScheduleSectionOpen,
    onOpen: onScheduleSectionOpen,
    onClose: onScheduleSectionClose,
  } = useDisclosure();
  const {
    isOpen: isManageTeamsOpen,
    onOpen: onManageTeamsOpen,
    onClose: onManageTeamsClose,
  } = useDisclosure();
  const {
    isOpen: isSubEventSectionOpen,
    onOpen: onSubEventSectionOpen,
    onClose: onSubEventSectionClose,
  } = useDisclosure();
  if (!currentEvent) {
    return <Navigate to="/404" />;
  }
  return (
    <Page>
      <Heading>Create Event</Heading>
      <CreateCourtSection
        eventId={currentEvent.id}
        isOpen={isCourtsOpen}
        onOpen={onCourtsOpen}
        onClose={onCourtsClose}
      />
      <br />
      <LeagueInfoSection
        eventId={currentEvent.id}
        isOpen={isLeagueInfoOpen}
        onOpen={onLeagueInfoOpen}
        onClose={onLeagueInfoClose}
      />
      <br />
      <ManageTeams
        isOpen={isManageTeamsOpen}
        onOpen={onManageTeamsOpen}
        onClose={onManageTeamsClose}
      />
      <br />
      <ScheduleCreationSection
        eventId={currentEvent.id}
        isOpen={isScheduleSectionOpen}
        onOpen={onScheduleSectionOpen}
        onClose={onScheduleSectionClose}
      />
      <br />
      <SubEventCreationSection
        eventId={currentEvent.id}
        isOpen={isSubEventSectionOpen}
        onOpen={onSubEventSectionOpen}
        onClose={onSubEventSectionClose}
      />
    </Page>
  );
};
