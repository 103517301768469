import CreateModal from '../../components/CreateModal';
import * as yup from 'yup';
import {useCreateSubEventMutation} from '../../app/api';
import React from 'react';
import {DateTimeInput} from '../../components/DateTimeInput';
import {CreateSubEvent, SubEvent} from '../../types/dto';

interface CreateSubEventProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  afterSubmit: () => void;
  eventId: number
}

const CreateSubEventModal: React.FC<CreateSubEventProps> = ({
                                                              isOpen,
                                                              onClose,
                                                              onOpen,
                                                              afterSubmit,
                                                              eventId,
                                                            }) => {
  const [createEvent] = useCreateSubEventMutation();


  return (
    <CreateModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      header={'Create SubEvent'}
      initialValues={{
        title: '',
        description: '',
        time: new Date(),
      }}
      inputs={[
        {
          htmlFor: 'title',
          label: 'Title',
          type: 'text',
        },
        {
          htmlFor: 'description',
          label: 'Description',
          type: 'text',
        },
        {
          htmlFor: 'time',
          label: 'Time',
          type: ({formik}) => (<DateTimeInput date={formik.values.time} onDateChange={(newDate) => {
            if (!isNaN(newDate.getTime())) {
              formik.setFieldValue('time', newDate);
            }
          }}/>)
        }
      ]}
      validationSchema={yup.object().shape({
        title: yup.string().required('Name is required'),
        description: yup.string().required('Description is required'),
        time: yup.date().required('Time is required')
      })}
      onSubmit={async (values, formik) => {
        const vals = values as CreateSubEventFormValues;
        const subEvent: Partial<SubEvent> = {
          title: vals.title,
          description: vals.description,
          time: vals.time
        }
        await createEvent({
          createSubEvent: subEvent as CreateSubEvent,
          eventId: eventId
        }).unwrap();
        formik.setSubmitting(false);
        onClose();
        afterSubmit();
      }}
    />
  );
};

interface CreateSubEventFormValues {
  title: string;
  description: string;
  time: Date
}

export default CreateSubEventModal;
