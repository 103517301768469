import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Stack,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetEventsForOrganizationQuery, useRefreshMutation } from 'app/api';
import { useAppSelector } from 'app/store';
import Card from 'components/Card/Card';
import Page from 'components/Page/Page';
import { canPerformOrganizationAction } from 'contexts/AuthContext/AuthContext';
import { useOrganizationContext } from 'contexts/OrganizationContext';
import CreateSportEventModal from 'pages/events/CreateSportEventModal';
import { useNavigate } from 'react-router-dom';
import { OrganizationMembersTable } from './OrganizationMembersTable';

export const OrganizationManagement = () => {
  const [refresh] = useRefreshMutation();
  const { organizationUser } = useAppSelector((state) => state.user);
  const { currentOrg } = useOrganizationContext();
  const navigate = useNavigate();
  const { data, isLoading, error } = useGetEventsForOrganizationQuery(
    currentOrg
      ? {
          organizationId: currentOrg.id,
        }
      : skipToken
  );
  const cardBGColor = useColorModeValue('orange.500', 'blue.500');
  const cardTextColor = useColorModeValue('white', 'black');
  const {
    isOpen: isCreateEventModalOpen,
    onOpen: onCreateEventModalOpen,
    onClose: onCreateEventModalClose,
  } = useDisclosure();

  return (
    <Page>
      <VStack>
        <Card>
          <Heading>Organization Management</Heading>
        </Card>
        <Stack direction={{ base: 'column', md: 'row' }} w="full">
          {canPerformOrganizationAction(
            organizationUser?.organizationRole,
            'ORG_ADMIN'
          ) && (
            <Card>
              <OrganizationMembersTable />
            </Card>
          )}
          <Card>
            <HStack justify="space-between">
              <Heading>Events</Heading>
              <IconButton
                aria-label="Add Event"
                variant="ghost"
                onClick={onCreateEventModalOpen}
                icon={<AddIcon />}
              />
            </HStack>
            {data?.map((event, i) => (
              <Box role="group" key={i}>
                <Card
                  onClick={() => navigate(`events/${event.id}`)}
                  w="100%"
                  _groupHover={{
                    bg: cardBGColor,
                    cursor: 'pointer',
                  }}
                >
                  <Heading
                    _groupHover={{
                      color: cardTextColor,
                    }}
                  >
                    {event.name}
                  </Heading>
                  <HStack>
                    <Text
                      _groupHover={{
                        color: cardTextColor,
                      }}
                    >
                      {new Date(event.startDate).toLocaleDateString()}-
                      {new Date(event.endDate).toLocaleDateString()}
                    </Text>
                    {canPerformOrganizationAction(
                      organizationUser?.organizationRole,
                      'ORG_ADMIN'
                    ) && (
                      <Tag
                        colorScheme={
                          event.config.flags.isLive ? 'green' : 'red'
                        }
                      >
                        <TagLabel>
                          {event.config.flags.isLive ? 'Active' : 'Hidden'}
                        </TagLabel>
                      </Tag>
                    )}
                  </HStack>
                </Card>
              </Box>
            ))}
          </Card>
        </Stack>
      </VStack>
      <CreateSportEventModal
        isOpen={isCreateEventModalOpen}
        onOpen={onCreateEventModalOpen}
        onClose={onCreateEventModalClose}
        afterSubmit={(id) => {
          refresh();
          navigate(`events/${id}/initialize`);
        }}
      />
    </Page>
  );
};
